import React, { useState, useEffect, useRef, useMemo } from 'react';
import Slider from "react-slick";
import { Row, Col, Select, Card, Button, Icon, Input, InputNumber, Typography, Tooltip } from "antd";
import OptionModal from "./OptionModal";
import ContentsOfCampaignHolder from "./Styles/contentsOfCampaignStyle"
import { useTranslation } from 'react-i18next';
import RequiredResolutionsCard from "./RequiredResolutionsCardComponent";
import { convertImage } from '../services/contents';
import TOLERANCES from "../constants/Tolerance";
import ModalContentComponent from "./ModalContentComponent";
import HideWhenCampaignActive from "../containers/HideWhenCampaignActiveContainer";
import { has, set } from 'lodash';

const { Text } = Typography;

function SliderNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", right: "-15px", top: "108px" }}
            onClick={onClick}
        />
    );
}

function SliderPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", left: "-30px", top: "108px", zIndex: "1" }}
            onClick={onClick}
        />
    );
}

const ContentsOfCampaignComponent = (props) => {
    const { t } = useTranslation();
    const {
        cart,
        listOfContentsInCampaign, // this are contents added to the campaign
        cleanListOfContentsInCampaign,
        deleteContentFromCampaign,
        listResolutionWithContent,
        setListResolutionWithContent,
        updateListOfContentsInCampaign,
        customContents,
        removeRelationFromDisplay,
        clearCustomContents,
        errors,
        groupedDisplays, // When modal open from the group display
        groupName,
        selectedContent,
        toleranceResolution,
        setToleranceResolution,
        isSuperUser,
        editCampaignActive,
        applyRelationOnScreens,
        removeRelationsOnCampaign,
        contentSelected,
        updateCustomContents,
        isPaymentStatusApproved,
        remplaceContentForUniqueVersion
    } = props;

    let sliderRef = useRef(null);

    const [visibleModalDeleteContentRelation, setVisibleModalDeleteContentRelation] = useState(false);
    const [contentModal, setContentModal] = useState(null); // Save the content to show in all modal (Delete and info View)
    const [deletingContentFromLibrary, setDeletingContentFromLibrary] = useState(false); // Is when delete content from library IN MODAL VIEW
    const [visibleModalCleanContent, setVisibleModalCleanContent] = useState(false);
    const [currentStick, setCurrentStick] = useState(0);
    const [loadingListOfResolutions, setLoadingListOfResolutions] = useState(true); // Waiting for the list of resolutions
    const [slidesToScroll, setSlidesToScroll] = useState(3);
    const [visibleModalContent, setVisibleModalContent] = useState(false);
    const [listResolution, setListResolution] = useState();
    const [hasDifferentResolution, setHasDifferentResolution] = useState(false);
    const [inputValue, setInputValue] = useState(toleranceResolution);

    // Filter the resolutions that have display is necessary by modal of group displays
    const resolutionSubtitle = useMemo(() => getResolucionSubtitle(), [groupedDisplays]);
    const resolutionTitle = useMemo(() => getResolucionTitle(), [groupedDisplays, listResolution]);

    const withCardInRelation = 130; // Define the width of the card in the relation
    const { Option } = Select;

    const settingsSlider = {
        infinite: false,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 3,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />,
        variableWidth: true,
        adaptiveHeight: false,
        swipeToSlide: true,
        responsive: [{
            breakpoint: 600,
            settings: { slidesToShow: 2, slidesToScroll: 2 }
        }, {
            breakpoint: 480,
            settings: { slidesToShow: 1, slidesToScroll: 1 }
        }]
    };

    function aspectRatiosMatch(contentWidth, contentHeight, screenWidth, screenHeight) {
        // Calculate the aspect ratios of the content and the screen
        const contentAspectRatio = contentWidth / contentHeight;
        const screenAspectRatio = screenWidth / screenHeight;

        let blackBarsPercentage = 0;

        // Adjust and calculate the black bars percentage
        if (contentAspectRatio > screenAspectRatio) {
            // The content is wider in proportion; adjust height
            const adjustedHeight = screenWidth / contentAspectRatio;
            const blackBarsHeight = screenHeight - adjustedHeight;
            blackBarsPercentage = (blackBarsHeight / screenHeight) * 100; // Percentage of black bars in height
        } else if (contentAspectRatio < screenAspectRatio) {
            // The content is taller in proportion; adjust width
            const adjustedWidth = screenHeight * contentAspectRatio;
            const blackBarsWidth = screenWidth - adjustedWidth;
            blackBarsPercentage = (blackBarsWidth / screenWidth) * 100; // Percentage of black bars in width
        } else {
            // Aspect ratios are equal; no black bars
            blackBarsPercentage = 0;
        }

        // Determine if the black bars percentage is within the allowed tolerance
        const isMatch = blackBarsPercentage <= toleranceResolution;

        // Return the result and the absolute difference between aspect ratios
        return [isMatch, Math.abs(contentAspectRatio - screenAspectRatio)];
    }

    function filteredDisplays() {
        const groupedDisplayIds = groupedDisplays.map(display => display.id);
        const customCart = cart.filter((display) => groupedDisplayIds.includes(display.id))
        return getListOfResolutions(deletingContentFromLibrary ?  cart : customCart);
    }

    function getClosestAspectRatio (contentAddedInRelation, listResolution, contentResolution) {
        if(contentAddedInRelation.width === listResolution.resolution_width && contentAddedInRelation.height === listResolution.resolution_height) {
            return contentAddedInRelation;
        }
        if(contentResolution.width === listResolution.resolution_width && contentResolution.height === listResolution.resolution_height) {
            return contentResolution;
        }

        const contentAddedInRelationAspectRatio = contentAddedInRelation.width / contentAddedInRelation.height;
        const listResolutionAspectRatio = listResolution.resolution_width / listResolution.resolution_height;
        const contentResolutionAspectRatio = contentResolution.width / contentResolution.height;

        const differenceWithContentAdded = Math.abs(contentAddedInRelationAspectRatio - listResolutionAspectRatio);
        const differenceWithContentResolution = Math.abs(contentResolutionAspectRatio - listResolutionAspectRatio);

        return differenceWithContentAdded < differenceWithContentResolution ? contentAddedInRelation : contentResolution;
    }

    function getResolucionSubtitle() {
        const isOnlyone = groupedDisplays && groupedDisplays.length === 1;

        const partOne = isOnlyone ? t('for the screen') : t('for screens grouped as');
        const partTwo = isOnlyone ? groupedDisplays[0].name : groupName;

        return `${partOne} ${partTwo}`;
    }

    function getResolucionTitle() {
        const isOnlyone = groupedDisplays && groupedDisplays.length === 1;
        const hasDifferentVersion = listResolution && listResolution[0] && listResolution[0].contents.some(content => content.content_version_name !== "V1");

        const partOne = isOnlyone ? t('Required resolution') : t('Required resolutions');
        const partTwo = hasDifferentVersion && isOnlyone && !contentSelected ? "para estas versiones" : "";


        return `${partOne} ${partTwo}`;
    }

    function pushFormattedContent (contentResolution, listResolution, contentMatch) {
        // Calculate content aspect ratio
        const contentAspectRatio = contentResolution.width / contentResolution.height;

        const screenAspectRatio = listResolution.resolution_width / listResolution.resolution_height;
        // Check if aspect ratios match within tolerance
        const [ismatch, difference] = aspectRatiosMatch(contentResolution.width , contentResolution.height, listResolution.resolution_width, listResolution.resolution_height) 

        if (selectedContent && selectedContent.id !== contentResolution.id) return;
        if (!ismatch) return;

        // Do not load all the content because it overloads the browser.
        const content = {
            ...contentResolution,
            id: contentResolution.id,
            file_thumb: contentResolution.file_thumb,
            name: contentResolution.name,
            content_version_name: contentResolution.content_version_name,
            differentResolution: difference ? ismatch : false,
        }
        contentMatch.push(content);
    }

    function matchResolutionContent(contentsInCampaign, listResolution) {
        // Iterate over each content resolution
        let contentMatch = [];
        const listDisplay = listResolution.displays.map(display => display.id);
        contentsInCampaign.forEach(contentResolution => {
            const contentAddedInRelation = contentMatch.find(relation => relation.content_version_name === contentResolution.content_version_name);
            if (contentAddedInRelation && contentAddedInRelation.id !== contentResolution.id) {
                if(!(contentAddedInRelation.height === listResolution.resolution_height && contentAddedInRelation.width === listResolution.resolution_width)) {
                    const contentClosest = getClosestAspectRatio(contentAddedInRelation, listResolution, contentResolution)
                    if(contentClosest.id != contentAddedInRelation.id) {
                        remplaceContentForUniqueVersion(contentAddedInRelation.id, listDisplay, contentResolution);
                        contentMatch = contentMatch.filter(contentM => contentM.id !== contentAddedInRelation.id);
                        pushFormattedContent(contentClosest, listResolution, contentMatch)
                    }
                }
            }

            if(!contentAddedInRelation) {
                pushFormattedContent(contentResolution, listResolution, contentMatch)
            }
        });

        // Order by content version
        contentMatch = contentMatch.sort((a, b) => {
            if (a.content_version_name && b.content_version_name) {
                return parseInt(a.content_version_name.slice(1)) - parseInt(b.content_version_name.slice(1));
            }
            return [];
        });
        return contentMatch;
    }

    // Create the relation depending of customContents
    function createRelation(uniqueResolutionsArray, actionWithRelation) {
        const { displays, contents } = uniqueResolutionsArray;
        let relations = [];

        // This case is when initial campaign
        if (!customContents ||
            customContents.length === 0 ||
            actionWithRelation === "cleanRelations" ||
            actionWithRelation === "deletingContentFromLibrary"
        ) {
            displays.forEach(display => {
                contents.forEach(content => {
                    relations.push({
                        displayId: display.id,
                        contentId: content.id
                    });
                });
            });
        } else { // When are updating campaign
            displays.forEach(display => {
                if (groupedDisplays) {
                    const groupedDisplayIds = groupedDisplays.map(display => display.id);
                    if (!groupedDisplayIds.includes(display.id)) return;
                }
                let filteredContents = customContents.filter(content => content.displayId === display.id);
                filteredContents.forEach(customContent => {
                    relations.push({
                        displayId: customContent.displayId,
                        contentId: customContent.content.id
                    });
                });

                if(actionWithRelation === "updateContent") {
                    const newContents = listOfContentsInCampaign
                    .filter(content => !customContents
                    .some(customContent => customContent.content.id === content.id));
                    // let newContents = listOfContentsInCampaign.find(content => content.id === newContents.id);
                    if (groupedDisplays && newContents[0]) { //Search all relationships to avoid adding content from previous ones
                        if (contentSelected) {
                            const customContentAux = customContents.map(customContent => {
                                if (customContent.displayId === display.id && customContent.content.id === contentSelected.id) {
                                    return { ...customContent, content: newContents[0]}
                                }
                                return customContent;
                            });
                            updateCustomContents(customContentAux)
                            relations = relations.map(relation => {
                                if (relation.displayId === display.id && relation.contentId === contentSelected.id) {
                                    return { displayId: display.id, contentId: newContents[0].id}
                                }
                                return relation;
                            });
                        }}
                }

                if (actionWithRelation === "addNewContent" || actionWithRelation === "deletingContentFromLibrary") {
                    let newContents;
                    if (groupedDisplays) { //Search all relationships to avoid adding content from previous ones
                        newContents = contents
                            .filter(content => !customContents
                            .some(customContent => customContent.content.id === content.id));
                    } else {
                        newContents = contents
                            .filter(content => !filteredContents
                            .some(customContent => customContent.content.id === content.id));
                    }
                    newContents.forEach(content => {
                        relations.push({
                            displayId: display.id,
                            contentId: content.id
                        });
                    });
                }
            });
        }
        return relations;
    };

    // Add relation and contents that match the resolution
    function addRelations(uniqueResolutionsArray, actionWithRelation) {
        let actionForCustomContents = "";
        const uniqueResolutions = uniqueResolutionsArray.map((relation) => {
            let newRelation = { ...relation, contents: [...relation.contents] };
            newRelation.contents = matchResolutionContent(listOfContentsInCampaign, relation);
            let hasDisplay = true;
            if (newRelation.contents.length === relation.contents.length) {
                actionForCustomContents = "updatedCustomContent";
            }
            if (groupedDisplays) {
                const groupedDisplayIds = groupedDisplays.map(display => display.id);
                hasDisplay = relation.displays.some(display => groupedDisplayIds.includes(display.id))
            }
            if (newRelation.contents.length > 0 && hasDisplay) {
                newRelation.contentsDisplays = createRelation(newRelation, actionWithRelation);
            }
            return newRelation;
        });
        return [uniqueResolutions, actionForCustomContents]
    }

    // Search set of resolutions in the added screens
    function getListOfResolutions(customCart) {
        const uniqueResolutions = {};
        customCart.forEach(item => {
            const resolutionKey = `${item.resolution_height}-${item.resolution_width}`;
            // Do not load all the display because it overloads the browser,
            // only the things that use in this view.
            const displayItem = {
                id: item.id,
                fill_screen: item.fill_screen,
                smart_campaign_cpm: item.smart_campaign_cpm,
            }
            if (!uniqueResolutions[resolutionKey]) {
                uniqueResolutions[resolutionKey] = {
                    resolution_height: item.resolution_height,
                    resolution_width: item.resolution_width,
                    countScreen: 1,
                    contents: [],
                    displays: [displayItem],
                    contentsDisplays: [],
                };
            } else {
                uniqueResolutions[resolutionKey].displays.push(displayItem);
                uniqueResolutions[resolutionKey].countScreen++;
            }
        });
        let uniqueResolutionsArray = Object.values(uniqueResolutions); // Convert object to array
        uniqueResolutionsArray = addRelations(uniqueResolutionsArray)[0];
        return uniqueResolutionsArray;
    }

    const handleContent = (value, content) => {
        let updatedContent = { ...content, name: value };
        updateListOfContentsInCampaign(updatedContent)
        if (editCampaignActive) {
            const updatedlist = listResolutionWithContent.map(relation => {
                const updatedContents = relation.contents.map(item => {
                    if (item.id === content.id) {
                        return { ...item, name: value };
                    }
                    return item;
                });
                return { ...relation, contents: updatedContents };
            });
            setListResolutionWithContent(updatedlist);
        }
    }

    const cleanProgress = () => {
        // Clean the list of contents in the campaign
        cleanListOfContentsInCampaign();
        clearCustomContents();
        showModalCleanContent();
    };

    const showModalDeleteContentRelation = (content) => {
        setContentModal(content)
        setVisibleModalDeleteContentRelation(!visibleModalDeleteContentRelation);
    };

    const showModalCleanContent = () => {
        setVisibleModalCleanContent(!visibleModalCleanContent);
    };

    const deleteContentOfListRelation = () => {
        setDeletingContentFromLibrary(true);
        deleteContentFromCampaign(contentModal.id);
        setVisibleModalDeleteContentRelation(!visibleModalDeleteContentRelation);
    };

    const showModalContent = (content) => {
        if (!content) return;
        setContentModal(content);
        setVisibleModalContent(true);
    };
    const cancelModalContent = () => {
        setContentModal(null);
        setVisibleModalContent(false);
    };

    const calculateSlidesToShow = () => {
        if (toleranceResolution >= 25) return setSlidesToScroll(1);
        if (toleranceResolution >= 15) return setSlidesToScroll(2);
        return setSlidesToScroll(3)
    }

    const setTolerance = (toleranceResolution) => {
        toleranceResolution = parseInt(toleranceResolution);
        const maxSuperUser = 50;
        const maxUser = 25;
        if (toleranceResolution > maxUser && !isSuperUser) return setToleranceResolution(maxUser);
        if (toleranceResolution > maxSuperUser && isSuperUser) return setToleranceResolution(maxSuperUser);
        if (toleranceResolution < 0 || !toleranceResolution ) return setToleranceResolution(0);
        setToleranceResolution(toleranceResolution);
    }

    const initSlider = () => {
        if (!sliderRef) return
        sliderRef.slickGoTo(0);
    }

    const updateListResolutions = (type) => {
        if (loadingListOfResolutions || listResolutionWithContent.length === 0) return;

        const updatedListResolution = addRelations(listResolutionWithContent, type);
        if (!groupedDisplays)
            setListResolutionWithContent(updatedListResolution[0]);

        setListResolution(updatedListResolution[0]);

        if(groupedDisplays) {
            const hasDifferentResolutionAux = updatedListResolution[0].some(item => item.contents.some(content => content.differentResolution === true));
            setHasDifferentResolution(hasDifferentResolutionAux);
        }

        if( groupedDisplays &&
            (type === "deletingContentFromLibrary" || updatedListResolution[1] != "updatedCustomContent")
        ) {
            applyRelationOnScreens(cart, updatedListResolution[0]);
        }
    };

    const defineListResolution = (listResolutionWithContent) => {
       // Filter the resolutions that have display is necessary by modal of group displays
        const listResolutionAux = groupedDisplays ? filteredDisplays() : listResolutionWithContent;
        const hasDifferentResolutionAux = listResolutionAux.some(item => item.contents.some(content => content.differentResolution === true));

        setListResolution(listResolutionAux);
        setHasDifferentResolution(hasDifferentResolutionAux);
    }

    // *** useEffect *** //
    useEffect(() => {
        if (!groupedDisplays) setListResolutionWithContent(getListOfResolutions(cart));
        setLoadingListOfResolutions(false);
        defineListResolution(listResolutionWithContent)
    }, []);

    useEffect(() => {
        initSlider();
        calculateSlidesToShow();
        updateListResolutions("cleanRelations");
    }, [toleranceResolution])

    useEffect(() => {
        if(!deletingContentFromLibrary){
            if( groupedDisplays && contentSelected) {
                updateListResolutions("updateContent");
            } else {
                updateListResolutions("addNewContent");
            }
        }
    }, [listOfContentsInCampaign]);

    useEffect(() => {
        if(deletingContentFromLibrary)
            updateListResolutions("deletingContentFromLibrary");
            defineListResolution(listResolutionWithContent);
            setDeletingContentFromLibrary(false);
    }, [deletingContentFromLibrary]);

    useEffect(() => {
        if (!deletingContentFromLibrary) {
            if (groupedDisplays && listResolution ) {
                listResolution.forEach(relation => {
                    listResolutionWithContent.forEach(relationWithContent => {
                        if (
                            relation.resolution_height === relationWithContent.resolution_height &&
                            relation.resolution_width === relationWithContent.resolution_width &&
                            relation.contents.length != relationWithContent.contents.length
                        ) {
                            defineListResolution(listResolutionWithContent);
                        }
                    });
                });
            } else {
                defineListResolution(listResolutionWithContent);
            }
        }
    }, [listResolutionWithContent]);


    const filteredContents = (relation) => {
        return (contentSelected
            ? relation.contents.filter((content) => contentSelected.id === content.id)
            : relation.contents);
    }

    return (
        <ContentsOfCampaignHolder withCardInRelation={withCardInRelation} >
            <Row>
                {!groupedDisplays &&
                    <Col xs={24} sm={24} md={24} lg={24} style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <Tooltip title={t('Clear all contents added to the campaign, clearing its progress')}>
                        <Button className="secondary buttonContent standard" onClick={() => showModalCleanContent()}>
                            <Icon type="delete" />
                            {t('Clear progress')}
                        </Button>
                    </Tooltip>
                    </Col>
                }
                <Col xs={24} sm={24} md={24} lg={24} >
                    <div className="slider-container">
                        {listOfContentsInCampaign.length === 0 ?
                            <div id="1" className='contentMessage'>
                                <p>{t("You must select a content")}</p>
                            </div>
                            :
                            <Slider {...settingsSlider}>
                                {listOfContentsInCampaign.length > 0 && listOfContentsInCampaign.map((content, index) => (
                                    <div key={index} style={{ padding: "0 8px" }}>
                                        <Card className='cardContent'>
                                            <div style={{ display: "flex" }}>
                                                <Input
                                                    size="small"
                                                    className='inputContentName'
                                                    placeholder={t('Identify your ad...')}
                                                    value={content.name}
                                                    onChange={e => handleContent(e.target.value, content)}
                                                />
                                                <span className={"primaryCircle"} >
                                                    {content.content_version_name || "V1"}
                                                </span>
                                            </div>
                                            <div className='centerCard'>
                                                {<img
                                                    src={convertImage(content.file_thumb)}
                                                    onClick={() => showModalContent(content)}
                                                    className="contentCardImage"
                                                    alt=""
                                                />}
                                            </div>
                                            <HideWhenCampaignActive contentId={content.id}>
                                                <div className='contentCardContent'>
                                                    <p className="textCard centerCard" > {content.width} x {content.height}</p>
                                                    <Tooltip title={t('Remove contents from the campaign')}>
                                                        <Button
                                                            shape="circle"
                                                            className="icon-card cardRed"
                                                            onClick={() => showModalDeleteContentRelation(content)}
                                                        >
                                                        <Icon type="delete" />
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </HideWhenCampaignActive>
                                        </Card>
                                    </div>
                                ))}
                            </Slider>
                        }
                    </div>
                </Col>
                <Col xs={20} sm={20} md={20} lg={20}>
                    <div className="ant-collapse-header collapse-resolution" style={{ marginTop: "10px", paddingBottom: "10px !important" }} role="button" tabIndex="0" aria-expanded="true">
                        <Row type="flex" align="middle" style={{ gap: 8 }}>
                            <Col xs={24}>
                                {resolutionTitle}
                            </Col>
                            {groupedDisplays && (
                                <Col xs={24}>
                                    <Row className="ant-collapse-subtitle" type="flex" align="middle" style={{ gap: 8 }}>
                                        {contentSelected ? (
                                            <span>
                                                {t("To replace this content")}
                                            </span>
                                        ) : (
                                            <>
                                                <Icon type="desktop" />
                                                <span>
                                                    {resolutionSubtitle}
                                                </span>
                                            </>
                                        )}
                                    </Row>
                                </Col>
                            )}
                        </Row>
                        {errors && errors["contentsDisplays"] &&
                            <p className="error-message error-message-contents-displays">
                                {t(errors["contentsDisplays"])}
                            </p>
                        }
                    </div>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4}>
                    <div class="contentTolerance" style={{ marginTop: '30px' }} role="button" tabIndex="0" aria-expanded="true">
                        <p style={{ marginRight: "5px" }}>{t("Tolerance")}:</p>
                        <InputNumber
                            style={{ width: 70 }}
                            value={toleranceResolution}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace('%', '')}
                            onChange={setTolerance}
                            min={0}
                            max={isSuperUser ? 50 : 25}
                        />
                        <Tooltip
                            placement="right"
                            title={t('Info tolerance')}
                        >
                            <Icon className="cardAudienceIconInfo" type="info-circle" theme="outlined" />
                        </Tooltip>
                    </div>
                </Col>
                {hasDifferentResolution && (
                    <Col xs={24} sm={24} md={24} lg={24} style={{ marginBottom: "20px" }} className="appear-animation">
                        <Row type="flex" style={{ gap: "10px", alignItems: "center" }}>
                            <Icon type="warning" theme="outlined" style={{ color: "#FAAD14" }} />
                            <Text style={{ color: "#888888", fontSize: "17px", fontStyle: "italic" }}>
                                {t("There are ads that do not adapt correctly to the resolutions. To continue, adjust the tolerance.")}
                            </Text>
                        </Row>
                    </Col>
                )}
                <Col xs={24} sm={24} md={24} lg={24}>
                    <div className="slider-container required-resolution">
                        <Slider
                            ref={slider => { sliderRef = slider }}
                            {...settingsSlider}
                            slidesToScroll={slidesToScroll}
                            afterChange={(current) => setCurrentStick(current)}
                        >
                            {listResolution && listResolution.map((relation, index) => (
                                <div key={index} style={{ padding: "0 8px", position: "relative" }}>
                                    {relation.contents.length > 0 ? (
                                        <div className="contentRelation" style={{ width: `${withCardInRelation + (relation.contents.length * 40)}px` }}>
                                            <Card>
                                                { filteredContents(relation).map((content, i) => (
                                                    <RequiredResolutionsCard
                                                        key={i}
                                                        customContents={customContents}
                                                        removeRelationFromDisplay={removeRelationFromDisplay}
                                                        setListResolutionWithContent={setListResolutionWithContent}
                                                        listResolutionWithContent={listResolutionWithContent}
                                                        loading={index < (currentStick - 8) || index > (currentStick + 16)}
                                                        className="multi"
                                                        style={{ left: `${i * 40}px`, zIndex: (relation.contents.length - i) }}
                                                        relation={relation}
                                                        content={content}
                                                        showModalDelete={showModalDeleteContentRelation}
                                                        isWarning={content.differentResolution}
                                                        removeRelationsOnCampaign={removeRelationsOnCampaign}
                                                        onClickImage={() => showModalContent(content)}
                                                        isPaymentStatusApproved={isPaymentStatusApproved}
                                                    />
                                                ))}
                                            </Card>
                                        </div>
                                    ) : (
                                        <RequiredResolutionsCard
                                            loading={index < (currentStick - 8) || index > (currentStick + 16)}
                                            relation={relation}
                                            showModalDelete={showModalDeleteContentRelation}
                                            removeRelationsOnCampaign={removeRelationsOnCampaign}
                                            onClickImage={() => showModalContent(relation)}
                                            isPaymentStatusApproved={isPaymentStatusApproved}
                                        />
                                    )}
                                </div>
                            ))}
                        </Slider>
                    </div>
                </Col>
            </Row>
            <ModalContentComponent
                visible={visibleModalContent}
                onCancel={cancelModalContent}
                content={contentModal}
            />
            <OptionModal
                title={t('Atention!')}
                body={t('Are you sure you want to remove this content from the campaign')}
                visible={visibleModalDeleteContentRelation}
                okText={t('OK')}
                cancelText={t('Cancel')}
                onOk={() => deleteContentOfListRelation()}
                onCancel={() => showModalDeleteContentRelation()}
                okButtonProps={{ loading: true }}
                width={600}
            />
            <OptionModal
                title={t('Atention!')}
                body={t('Are you sure you want to delete all progress so far?')}
                visible={visibleModalCleanContent}
                okText={t('OK')}
                cancelText={t('Cancel')}
                onOk={() => cleanProgress()}
                onCancel={() => showModalCleanContent()}
                okButtonProps={{ loading: true }}
            />
        </ContentsOfCampaignHolder>
    )
}

export default ContentsOfCampaignComponent;