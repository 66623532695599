import React, { Fragment, Component } from "react";
import ScreenPreviewHolder from "./Styles/screenPreviewStyle";
import Rectangle from "react-rectangle";
import { Alert, Button, Icon, Card, Switch, Upload, message, Spin, Tag, Tooltip, Col, InputNumber, DatePicker, TimePicker, Checkbox, Input, Form, Modal, Progress, Popover } from "antd";
import { get, random, remove, clone, cloneDeep } from "lodash";
import ScreenPreview from "../containers/ScreenPreviewContainer";
import Slider from "react-slick";
import moment from "moment";
import canvaLogoEdit from "../images/canva-icon-edit.png";
import canvaLogoAdd from "../images/canva-icon-add.png";
import placeholder from "../images/placeholder.jpg";
import { withTranslation } from 'react-i18next';
import ScreenContainer from "../containers/ScreenContainer";
import { getWorkingHoursList} from "../services/display";
import UserRoleEnum from '../constants/UserRoleEnum';
import { hasAccessRole, calculateValueByCpm, getRelationId } from "../services/utils"
import { CSSTransition } from 'react-transition-group';
import CSSEffect from "./Styles/CSSEffect";
import AudienceSummaryComponentGeneral from "./AudienceSummaryComponentGeneral";
import NumberFormat from "./NumberFormat";
import Colors from "../themes/Colors";
import TagsDiscount from "./TagsDiscount";
import { AimSvg } from "../images/customIcons/AimSvg";
import { getCPM } from "../services/display";
import ImpressionManagementComponent  from "./ImpressionManagementComponent";
import { createNameNewContent } from "../services/contents";

const AimIcon = (props) => <Icon component={AimSvg} {...props} />;

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const { TextArea }  =  Input;

function SliderNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "-10px", top: "17px"}}
      onClick={onClick}
    />
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "-10px", top: "17px", zIndex: "1"}}
      onClick={onClick}
    />
  );
}

class ScreenTypePreviewComponent extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      previuStatusCpm: null,
      viewAlert: true,
      isHidden: true,
      rules: [],
      arrayTime: {
        "type":"hour",
        "from": "00:00",
        "to": "23:59",
      },
      arrayDays: {
        "type":"day",
        "value":[]
      },
      relation: {},
      uploadingCanvaFile: false,
      uploadingFile: false,
      canvaModalVisible: false,
      width: 300,
      height: 300,
      visibleWarningNoAutomateView: false,
      totalSubsidizePromisedShowsAllRelations: 0,
    };
  }
  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    });
  }

  componentDidMount() {
    this.props.customContents.forEach(relation => {
      if (relation.content && !relation.content.ready) {
        this.props.updateCustomContentRequest(relation.content.id, relation.displayId, relation.relationId);
      }
      // Check if rule have value=[] and remplace with vale[1,2,3,4,5,6,0]
      if(relation.rules){
        relation.rules.map( rule =>{
          var newRules = cloneDeep(relation.rules);
          if(rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.length<1){
              newRules.forEach( function(valor, indice) {
                var ruleIndice = indice
                newRules[ruleIndice].conditions.forEach( function(valor, indice){
                  if(valor.type=="day"){
                    newRules[ruleIndice].conditions[indice].value.push(1,2,3,4,5,6,0)
                  }
                })
              });
              this.props.displays.map(d => this.props.updateRulesRelationScreen(d.id, relation.relationId, newRules))
          }
        })
      }
    });
    this.props.setBudget(this.props.amountBudget);

    if (this.props.customContents.length > 0) {
      // If exist customContents, set new budget
      var newAmount = 0;
      this.props.customContents.forEach(relation => {
        if (!this.props.cart.find(display => display.id === relation.displayId)) {
          this.props.removeRelationFromCustomContents(relation.displayId);
        } else {
          newAmount = newAmount + (relation.promised_shows * this.props.cart.find(display => display.id === relation.displayId).smart_campaign_cpm/1000);
        }
      });
      if (newAmount > 0) {
        this.props.updateRealAmount(newAmount);
      } else {
        this.props.updateRealAmount(this.props.amountBudget);
      }
    }
    if (this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved') {
      this.setState({
        totalPromisedShowsAllRelations: this.props.currentCampaign.contents_displays.filter(customContent => customContent.display_id === this.props.displays[0].id)
                                                                                    .reduce((sum, customContent) => {return sum + (customContent.promised_shows - customContent.promised_shows_subsidized)}, 0)
      });
      this.setState({
        totalSubsidizePromisedShowsAllRelations: this.props.currentCampaign.contents_displays.filter(customContent => customContent.display_id === this.props.displays[0].id)
                                                                                             .reduce((sum, customContent) => {return  (sum + (customContent.promised_shows_subsidized == undefined ? 0 :
                                                                                                                                              customContent.promised_shows_subsidized))}, 0)
      });
    } else {
      this.setState({
        totalPromisedShowsAllRelations: this.props.customContents.filter(customContent => customContent.displayId === this.props.displays[0].id)
                                                                 .reduce((sum, customContent) => {return sum + customContent.promised_shows}, 0)
      });
      this.setState({
        totalSubsidizePromisedShowsAllRelations: this.props.customContents.filter(customContent => customContent.displayId === this.props.displays[0].id)
                                                                          .reduce((sum, customContent) => {return (sum + (customContent.promised_shows_subsidized == undefined ? 0 :
                                                                                                                          customContent.promised_shows_subsidized))}, 0)
      });
    }
  }
  GetImpressionsToShowPerDay(fromDate, toDate, promisedShows, rules) {
    var fromDateOnlyDate = moment(fromDate).format('YYYY-MM-DD');
    var toDateOnlyDate = moment(toDate).format('YYYY-MM-DD');
    var impresion;
    var days;
    var listDays = [];
    // If there is rules, evaluate wich days the content has to be shown
    if (!!rules && (rules.length > 0)) {
      var daysToShow = 0;
        // Iterate over all dates between fromDate and toDate
        // Eval all rules and conditions to check if content will be shown this date
        rules.forEach(function(rule){
          rule.conditions.forEach(function(condition){
            if (condition.type === 'date') {
              fromDateOnlyDate = moment(condition.from_date).format('YYYY-MM-DD');
              toDateOnlyDate = moment(condition.to_date).format('YYYY-MM-DD');
            }
          });
          for (var dateToEval = moment(fromDateOnlyDate); moment(toDateOnlyDate).diff(dateToEval, 'days') >= 0; dateToEval.add(1, 'days')) {
              var conditionsResult = true;
              if (!!rule.conditions && Array.isArray(rule.conditions)) {
                rule.conditions.forEach(function(condition){
                  if (condition.type === 'day') {
                    // If actual day is not in array, set conditionsResult as false
                    // check if you do not have any day selected
                    if(!(listDays.indexOf(dateToEval.format('YYYY-MM-DD')) == -1)){
                      conditionsResult = false;
                    }else{
                      if (condition.value.indexOf(dateToEval.day()) == -1 ) {
                        conditionsResult = false;
                      }
                    }
                  }
                });
              }
              if (conditionsResult === true) {
                listDays.push(dateToEval.format('YYYY-MM-DD')) // Save day counted because repeatwhen have more rules
                daysToShow++;
              }
          }
        });
      days = daysToShow
    } else { // If there is no rules, shows per day is promised shows divided total days of campaign
      days = (moment(toDateOnlyDate).diff(moment(fromDateOnlyDate), 'days')+1)
    }
    impresion = days===0 ? 0 : Math.round( promisedShows / days)
    var t = this.props ? this.props.t : this.t
    var res = t('The spot will be seen n_days days, with n_impressions impressions per days')
    // return res.replace('n_days', days).replace('n_impressions', impresion)
    return days
  };

  // Calculate the total promises of a display, sum up all the promises from the relationships present on that display
  calculatePromisedShows = (displayId) => {
    let totalPromisedShows = 0
    let sumTotalSubsidized = 0
    this.props.customContents.forEach(function(customContent) {
      if(customContent.displayId === displayId){
        totalPromisedShows = totalPromisedShows + customContent.promised_shows
        sumTotalSubsidized = sumTotalSubsidized + (customContent.promised_shows_subsidized ? customContent.promised_shows_subsidized : 0)
      }}
    );

    return totalPromisedShows - sumTotalSubsidized
  }

  totalPromisedShowsForCampaignApproved = (displayId) => {
    const { customContents, currentCampaign} = this.props;
    var totalAllRelations = 0

    customContents.forEach(customContent => {
      if (customContent.displayId === displayId) {
        let currentCustomContent = currentCampaign.contents_displays.find(c => c.id === customContent.relationId)

        if( customContent.promised_shows_update_in_transfer && customContent.promised_shows_update_in_transfer != 0 ){
          let promised_shows_subsidized = currentCustomContent ? currentCustomContent.promised_shows_subsidized : customContent.promised_shows_subsidized
          promised_shows_subsidized = promised_shows_subsidized || 0
          // This case subsidized is not in pomised_shows becasue is customContent not currentCustomContent
          totalAllRelations = totalAllRelations + customContent.promised_shows;
        } else {
          let promised_shows = currentCustomContent ? currentCustomContent.promised_shows : customContent.promised_shows
          let promised_shows_subsidized = currentCustomContent ? currentCustomContent.promised_shows_subsidized : customContent.promised_shows_subsidized
          promised_shows_subsidized = promised_shows_subsidized || 0
          totalAllRelations = totalAllRelations + promised_shows - promised_shows_subsidized;
        }
      }
    });
    return totalAllRelations
  }

  componentDidUpdate(prevProps, prevState) {
    // Then apply change in transfer impression If the customContents change
    if(prevProps.customContents !== this.props.customContents
       && (prevProps.transferModalVisible !== this.props.transferModalVisible )
       && !this.props.transferModalVisible
       && this.state.totalPromisedShowsAllRelations === prevState.totalPromisedShowsAllRelations){
        var { displays } = this.props
        let totalPromisedShows = this.calculatePromisedShows(displays[0].id)
        this.setState({totalPromisedShowsAllRelations: totalPromisedShows})
    }
  }
  
  calculateMax = (displayId, relation) => {
    const { editCampaignActive, currentCampaign, customContents, reportSingleData } = this.props;

    if (editCampaignActive && currentCampaign.payment_status === 'approved') {
      const customContentFilterDisplay = customContents
        .filter(customContent => customContent.displayId === displayId)

      let totalAllRelations = this.totalPromisedShowsForCampaignApproved(displayId)

      // Promise shows of other relation
      const customContentFilter = customContentFilterDisplay
        .filter(customContentRelation => customContentRelation.relationId !== relation.relationId);

      const customContentSum = customContentFilter.reduce((sum, customContentRelation) => {
        let promisedShows =
          customContentRelation.promised_shows_update_in_transfer != 0 ?
          customContentRelation.promised_shows_update_in_transfer :
          customContentRelation.promised_shows
        return sum + promisedShows
      }, 0);
      const reportElement = reportSingleData.report && reportSingleData.report.find(element =>
        element.display === displayId && element.content === relation.content.id);

      const reportShows = reportElement ? reportElement.shows : 0;

      return totalAllRelations  - customContentSum - reportShows;
    } else {
      return 999999999;
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {permissionsTransferSpots} = this.props
    if(!nextProps.fetching)
      this.setState({ uploadingFile: false });

    if (this.props.loadingUploadFile && !nextProps.loadingUploadFile && this.state.uploadingCanvaFile) {
      setTimeout(() => {
        const info = {
          file: {
            response:this.props.fileNew.resp.data,
            status: this.props.fileNew.resp.status
          },
        }
        this.setState({
          uploadingCanvaFile: false,
        });
        this.onChangeContentState(info, this.state.relation, this.props.displays.length > 1 ? ('multiple') : ('single'));
      }, 1000);
    }
    let sumTotal = 0
    let sumTotalSubsidized = 0
    if (this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved') {
      // admin and superModeratorAgency can change impressions subsidized, so render with custom Content
      nextProps.customContents.forEach(function(customContent) {
        if(customContent.displayId === nextProps.displays[0].id){
          if(permissionsTransferSpots){
            sumTotal = sumTotal + customContent.promised_shows
          }
          sumTotalSubsidized = sumTotalSubsidized + (customContent.promised_shows_subsidized ? customContent.promised_shows_subsidized : 0)
        }}
      );
    } else {
      nextProps.customContents.forEach(function(customContent) {
        if(customContent.displayId === nextProps.displays[0].id){
          sumTotal = sumTotal + customContent.promised_shows
          sumTotalSubsidized = sumTotalSubsidized + (customContent.promised_shows_subsidized ? customContent.promised_shows_subsidized : 0)
        }}
      );
      this.setState({totalPromisedShowsAllRelations: sumTotal})
    }
    this.setState({totalSubsidizePromisedShowsAllRelations: sumTotalSubsidized})
  }

  showTransferModal = (relationId) => {
    this.props.showTransferModal(relationId);
  }

  updateImpressionsDisplay(displayId, relation, e) {
    if (this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved') {
      let min = this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id) ? this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id).shows : 1
      let max = this.state.totalPromisedShowsAllRelations - this.props.customContents.filter(customContent => customContent.displayId === this.props.displays[0].id).filter(customContentRelation => customContentRelation.relationId !== relation.relationId).reduce((sum, customContentRelation) => {return sum + customContentRelation.promised_shows}, 0)

      if ( min < e < max) {
        this.props.updateImpressionsDisplay(displayId, relation.relationId, e);
      } else {
        return;
      }
    } else {
      this.props.updateImpressionsDisplay(displayId, relation.relationId, e);
    }
    const customContents = this.props.customContents.filter(c => c.displayId === displayId)
    var totalPromise = 0;
    let prevStatus = false //is for initialization of alert messenger
    e = (e == "0") ? 0 : e
    for (var i = 0; i < customContents.length; i++) {
      var addPromise = (relation.relationId == customContents[i].relationId) ? e : customContents[i].promised_shows
      totalPromise = totalPromise + addPromise
    }
    if(this.state.previuStatusCpm == null){
      var prevTotalPromise = 0
      for (var i = 0; i < customContents.length; i++) {
        prevTotalPromise = prevTotalPromise + customContents[i].promised_shows
      }
      if((prevTotalPromise < totalPromise ) & calculateValueByCpm(this.props.displays[0].smart_campaign_cpm, prevTotalPromise, "difference_cpm_price") > 0){
        prevStatus = true
      }
    }

    this.showAlertCmpMin(calculateValueByCpm(this.props.displays[0].smart_campaign_cpm, totalPromise, "difference_cpm_price"), prevStatus)
  }

  updateImpressionsSubsidizeDisplay(relationId, e) {
    if (!(0 < e < 99999999)) {
      e = 99999999
    }
    this.props.updatePromisedShowsSubsidized(relationId, e, 'single')
  }
  addRule = (relation) => {
    let newRules = clone(relation.rules);
    if (!newRules) {
      newRules = [];
    }
    newRules.push({"conditions":[]});

    this.state.arrayTime = {
      "type":"hour",
      "from": this.props.displays[0].working_hours[0] ? this.props.displays[0].working_hours[0].start_time.slice(0, 5) : "00:00",
      "to": this.props.displays[0].working_hours[0] ? this.props.displays[0].working_hours[0].end_time.slice(0, 5) : "23:59",
    }
    this.state.arrayDays = {
      "type":"day",
      "value": [1,2,3,4,5,6,0]
    }
    newRules[relation.rules.length].conditions.push(this.state.arrayTime)
    newRules[relation.rules.length].conditions.push(this.state.arrayDays)
    this.props.displays.map(d => this.props.updateRulesRelationScreen(d.id, relation.relationId, newRules))
  };

  removeAllRules = (relation) => {
    const rulesEmpty = [];
    this.props.displays.map(d => this.props.updateRulesRelationScreen(d.id, relation.relationId, rulesEmpty))
  };

  removeRule = (rule, indexToRemove, relation) => {
    let newRules = clone(relation.rules);
    remove(newRules, function(rule, index) {
      return index === indexToRemove;
    });
    this.props.displays.map(d => this.props.updateRulesRelationScreen(d.id, relation.relationId, newRules))
  };

  cloneAllRules = (relation) => {
    this.props.cloneRulesRelationScreen(relation.rules)
  };

  changeDate = (date, dateString, index, relation) => {
    const newRules = cloneDeep(relation.rules);
    if (dateString[0]==="" && dateString[1]===""){
      dateString[0] = this.props.date_from
      dateString[1] = this.props.date_to
    }else{
      dateString[0] = moment(dateString[0], "DD/MM/YYYY").format("YYYY-MM-DD")
      dateString[1] = moment(dateString[1], "DD/MM/YYYY").format("YYYY-MM-DD")
    }
    const arrayDate = {"type":"date","from_date":dateString[0],"to_date":dateString[1]}
    if (newRules[index].conditions.find(condition => condition.type === 'date')) {
      remove(newRules[index].conditions, function(condition) {
        return condition.type === 'date';
      });
      newRules[index].conditions.push(arrayDate)
      this.props.displays.map(d => this.props.updateRulesRelationScreen(d.id, relation.relationId, newRules))
    } else {
      newRules[index].conditions.push(arrayDate)
      this.props.displays.map(d => this.props.updateRulesRelationScreen(d.id, relation.relationId, newRules))
    }
  };

  changeDay = (e, index, relation) => {
    let newRules = cloneDeep(relation.rules);
    if (newRules[index].conditions.find(condition => condition.type === 'day')) {
      this.state.arrayDays = {
        "type":"day",
        "value":[]
      }
      var daysRules = newRules[index].conditions.find(condition => condition.type === 'day')
      for (var i = 0; i < daysRules.value.length; i++) {
        this.state.arrayDays.value.push(daysRules.value[i])
      }
    } else {
      this.state.arrayDays = {
        "type":"day",
        "value":[]
      }
    }
    if (e.target.checked) {
      if (parseInt(e.target.value) === 1) {
        this.state.arrayDays.value.push(1)
      } else if (parseInt(e.target.value) === 2) {
        this.state.arrayDays.value.push(2)
      } else if (parseInt(e.target.value) === 3) {
        this.state.arrayDays.value.push(3)
      } else if (parseInt(e.target.value) === 4) {
        this.state.arrayDays.value.push(4)
      } else if (parseInt(e.target.value) === 5) {
        this.state.arrayDays.value.push(5)
      } else if (parseInt(e.target.value) === 6) {
        this.state.arrayDays.value.push(6)
      } else if (parseInt(e.target.value) === 0) {
        this.state.arrayDays.value.push(0)
      }
    } else {
      if (parseInt(e.target.value) === 1) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 1;
        });
      } else if (parseInt(e.target.value) === 2) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 2;
        });
      } else if (parseInt(e.target.value) === 3) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 3;
        });
      } else if (parseInt(e.target.value) === 4) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 4;
        });
      } else if (parseInt(e.target.value) === 5) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 5;
        });
      } else if (parseInt(e.target.value) === 6) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 6;
        });
      } else if (parseInt(e.target.value) === 0) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 0;
        });
      }
    }
    if (newRules[index].conditions.find(condition => condition.type === 'day')) {
      remove(newRules[index].conditions, function(condition) {
        return condition.type === 'day';
      });
      newRules[index].conditions.push(this.state.arrayDays)
      this.props.displays.map(d => this.props.updateRulesRelationScreen(d.id, relation.relationId, newRules))
    } else {
      newRules[index].conditions.push(this.state.arrayDays)
      this.props.displays.map(d => this.props.updateRulesRelationScreen(d.id, relation.relationId, newRules))
    }
  };

  changeStartTime = (time, timeString, index, relation) => {
    let newRules = cloneDeep(relation.rules);
    if (newRules[index].conditions.find(condition => condition.type === 'hour')) {
      this.state.arrayTime = {}
      this.state.arrayTime = newRules[index].conditions.find(condition => condition.type === 'hour')
    } else {
      this.state.arrayTime = {
        "type":"hour",
        "from": this.props.displays[0].working_hours[0] ? this.props.displays[0].working_hours[0].start_time : "00:00",
        "to": this.props.displays[0].working_hours[0] ? this.props.displays[0].working_hours[0].end_time : "23:59",
      }
    }
    this.state.arrayTime.from = timeString;
    if (newRules[index].conditions.find(condition => condition.type === 'hour')) {
      remove(newRules[index].conditions, function(condition) {
        return condition.type === 'hour';
      });
      newRules[index].conditions.push(this.state.arrayTime)
      this.props.displays.map(d => this.props.updateRulesRelationScreen(d.id, relation.relationId, newRules))
    } else {
      newRules[index].conditions.push(this.state.arrayTime)
      this.props.displays.map(d => this.props.updateRulesRelationScreen(d.id, relation.relationId, newRules))
    }
  };

  changeEndTime = (time, timeString, index, relation) => {
    let newRules = cloneDeep(relation.rules);
    if (newRules[index].conditions.find(condition => condition.type === 'hour')) {
      this.state.arrayTime = {}
      this.state.arrayTime = newRules[index].conditions.find(condition => condition.type === 'hour')
    } else {
      this.state.arrayTime = {
        "type":"hour",
        "from": this.props.displays[0].working_hours[0] ? this.props.displays[0].working_hours[0].start_time : "00:00",
        "to": this.props.displays[0].working_hours[0] ? this.props.displays[0].working_hours[0].end_time : "23:59",
      }
    }
    this.state.arrayTime.to = timeString;
    if (newRules[index].conditions.find(condition => condition.type === 'hour')) {
      remove(newRules[index].conditions, function(condition) {
        return condition.type === 'hour';
      });
      newRules[index].conditions.push(this.state.arrayTime)
      this.props.displays.map(d => this.props.updateRulesRelationScreen(d.id, relation.relationId, newRules))
    } else {
      newRules[index].conditions.push(this.state.arrayTime)
      this.props.displays.map(d => this.props.updateRulesRelationScreen(d.id, relation.relationId, newRules))
    }
  };
  // If delete relation, send impressionsForDeletedRelation && addNewRelation = False
  // If add relation, send addNewRelation = True
  updateImpressions = (customContents, addNewRelation, impressionsForDeletedRelation) => {
    var displayRealShows = this.props.reportSingleData.report.filter(relation => relation.display === this.props.displays[0].id)
    var totalDisplayRealShows = displayRealShows.reduce((sum, relation) => {return sum + relation.shows}, 0)

    if (this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved') {
      var totalDisplayPromisedShows = customContents.filter(customContent => customContent.displayId === this.props.displays[0].id).reduce((sum, customContent) => {return sum + customContent.promised_shows}, 0)
      var totalPromisedShows = this.state.totalPromisedShowsAllRelations
    } else {
      var totalDisplayPromisedShows = customContents.reduce((sum, customContent) => {return sum + customContent.promised_shows}, 0)
      var totalPromisedShows = this.props.currentCampaign.contents_displays.reduce(
        (acc, content_display) => {
          return acc + content_display.promised_shows;
        },
        0
      );
    }

    if ((totalDisplayPromisedShows - totalDisplayRealShows) < 2 && !impressionsForDeletedRelation) {
      message.error(this.props.t('Unable to distribute due to lack of remaining prints'));
    } else {
      let new_promise_show
      if (addNewRelation) {
        const numDisplays = customContents.length + 1;
        new_promise_show = Math.trunc((totalDisplayPromisedShows - totalDisplayRealShows) / numDisplays)
        const rest = (totalDisplayPromisedShows - totalDisplayRealShows) % numDisplays;

        this.props.addRelationDisplay(this.props.displays[0].id, this.props.displays[0].id + '_' + (customContents.length + random(99999999)), new_promise_show + rest)
      }
      for (var i = 0; i < customContents.length; i++) {
        if (customContents[i].displayId && customContents[i].relationId) {
          let new_promise_show_relation = 0
          if(new_promise_show) {
            let promise_real_shows_relation = displayRealShows.filter(relation => relation.content === customContents[i].content.id).reduce((sum, relation) => {return sum + relation.shows}, 0)
            new_promise_show_relation = new_promise_show + promise_real_shows_relation
          } else if (impressionsForDeletedRelation) { // If delete relation, update the rest of relations
            let promise_real_shows_relation = displayRealShows.filter(relation => relation.content === customContents[i].content.id).reduce((sum, relation) => {return sum + relation.shows}, 0)
            new_promise_show_relation = impressionsForDeletedRelation - promise_real_shows_relation
          } else {
            new_promise_show_relation = customContents[i].promised_shows + totalPromisedShows - totalDisplayPromisedShows
          }
          this.props.updateImpressionsDisplay(customContents[i].displayId, customContents[i].relationId, new_promise_show_relation);
        }
      }
    }
  }

  // updateButtonImpressions = (relation) => {
  //   if (this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved') {
  //     var totalDisplayPromisedShows = this.props.customContents.filter(customContent => customContent.displayId === this.props.displays[0].id).reduce((sum, customContent) => {return sum + customContent.promised_shows}, 0)
  //     var totalPromisedShows = this.state.totalPromisedShowsAllRelations
  //     var totalDisplayRealShows = this.props.reportSingleData.report.filter(relation => relation.display === this.props.displays[0].id).reduce((sum, relation) => {return sum + relation.shows}, 0)
  //   } else {
  //     var totalDisplayPromisedShows = this.props.customContents.reduce((sum, customContent) => {return sum + customContent.promised_shows}, 0)
  //     var totalDisplayRealShows = this.props.reportSingleData.report.filter(relation => relation.display === this.props.displays[0].id).reduce((sum, relation) => {return sum + relation.shows}, 0)
  //     var totalPromisedShows = this.props.currentCampaign.contents_displays.reduce(
  //       (acc, content_display) => {
  //         return acc + content_display.promised_shows;
  //       },
  //       0
  //     );
  //   }
  //   if ((totalDisplayPromisedShows - totalDisplayRealShows) < 1) {
  //     message.error(this.props.t('Unable to distribute due to lack of remaining prints'));
  //   } else {
  //     if (relation.relationId) {
  //       this.props.updateImpressionsDisplay(this.props.displays[0], relation.relationId, relation.promised_shows + totalPromisedShows - totalDisplayPromisedShows);
  //     }
  //   }
  // }

  addRelationDisplay = (customContents, type) => {
    this.setState({ previuStatusCpm: null }); // Initialize state for alert message of cpm
    if (this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved' && type != "newRelation") {
      this.updateImpressions(customContents, true);
    } else {
      var impressions = 0
      customContents.forEach(customContent => {
        impressions = customContent.promised_shows + impressions
      });
      const numDisplays = customContents.length + 1;
      const impressionsPerDisplay = Math.trunc(impressions / numDisplays)
      const rest = impressions % numDisplays;

      impressions = Math.trunc(impressions / (customContents.length + 1))
      this.props.addRelationDisplay(this.props.displays[0].id, this.props.displays[0].id + '_' + (customContents.length + random(99999999)), impressionsPerDisplay + rest )
      for (var i = 0; i < customContents.length; i++) {
        if (customContents[i].displayId && customContents[i].relationId) {
          this.props.updateImpressionsDisplay(customContents[i].displayId, customContents[i].relationId, impressionsPerDisplay);
        }
      }
    }
    SliderNextArrow(this.props)
    this.slider.slickGoTo(customContents.length + 1)
  };

  removeRelationFromDisplayView = (displayId, relationId, customContents) => {
    this.props.removeRelationFromDisplay(displayId, relationId)
    var impressions = 0
    customContents.forEach(customContent => {
      impressions = customContent.promised_shows + impressions
    });

    impressions = Math.trunc(impressions / (customContents.length - 1))
    if (this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved') {
      this.updateImpressions(customContents, false, impressions);
    } else {
      for (var i = 0; i < customContents.length; i++) {
        if (customContents[i].displayId && customContents[i].relationId) {
          this.props.updateImpressionsDisplay(customContents[i].displayId, customContents[i].relationId, impressions);
        }
      }
    }
  };

  onChangeContentState = (info, relation, typePreview) =>{
    this.setState({ uploadingFile: true });
    this.onChangeContent(info, relation, typePreview)
  }

  onChangeContent = async (info, relation, typePreview) => {
      const status = info.file.status;
      if (status === "done" || status === 201) {
        this.props.displays.map(async d => {
          if (relation && relation.relationId.toString().search(d.id + '_') < 0) {
            this.props.editSingleReport(relation.content.id, info.file.response.id, d.id)
          }
          if (relation) {
            this.props.addCustomContent(info.file.response, d.id, relation.relationId, typePreview);
          }
          if (typePreview === 'multiple') {
            while (this.props.customContents.find(c => c.displayId === d.id) && !this.props.customContents.find(c => c.displayId === d.id).content.ready) {
              await new Promise(resolve => setTimeout(resolve, 7000));
              this.props.updateCustomContentRequest(info.file.response.id, d.id, relation.relationId, typePreview);
            }
          } else {
            let flagReady = false;
            while (this.props.customContents.find(c => c.relationId === (relation ? (relation.relationId) : (this.props.customContents[this.props.customContents.length - 1].relationId))) &&
                  !(relation ? (this.props.customContents.find(c => c.relationId === relation.relationId).content.ready) : flagReady)) {
              await new Promise(resolve => setTimeout(resolve, 5000));
              this.props.updateCustomContentRequest(info.file.response.id, d.id, (relation ? (relation.relationId) : (this.props.customContents[this.props.customContents.length - 1].relationId)), typePreview);
              await new Promise(resolve => setTimeout(resolve, 2000));
              flagReady = this.props.customContents[this.props.customContents.length - 1].content ? (this.props.customContents[this.props.customContents.length - 1].content.ready) : false
            }
          }
        });
        message.success(`${info.file.name} ${this.props.t('successfully uploaded')}`, 7);
      } else if (status === "error") {
        message.error(`${info.file.name} ${this.props.t('Upload error')}`, 7);
      }
  };

  createCanvaDesign = (relation) => {
    this.handleCanvaClose();
    this.props.canva.createDesign({
      design: {
        type: 'Poster',
        dimensions: {
          width: this.state.width ? this.state.width : 300,
          height: this.state.height ? this.state.height : 300,
          units: 'px',
        },
      },
      editor: {
        publishLabel: 'Guardar',
      },
      onDesignOpen: ({ designId }) => {
        // Triggered when editor finishes loading and opens a new design.
        // You can save designId for future use.
      },
      onDesignPublish: ({ exportUrl, designId }) => {
        // Triggered when design is published to an image.
        // Save the image to your server as the exportUrl will expire shortly.
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", exportUrl);
        xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
        xhr.onload = () => {
          blob = xhr.response;//xhr.response is now a blob object
          setTimeout(() => {
            blob.lastModifiedDate = new Date();
            blob.name = 'Diseño en canva';
            blob.canva_id = designId;
            blob.file = blob;
            this.setState({
              relation: relation
            });
            this.setState({
              uploadingCanvaFile: true,
            });
            this.props.uploadFileRequest(blob)
          }, 2000);
        }

        xhr.send();
      },
      onDesignClose: () => {
        // Triggered when editor is closed.
        console.log('Cerró canva');
      },
    });
  }

  editCanvaDesign = (relation) => {
    this.props.canva.editDesign({
      design: {
        type: 'Poster',
        id: relation.content.canva_id
      },
      editor: {
        publishLabel: 'Guardar',
      },
      onDesignOpen: ({ designId }) => {
        // Triggered when editor finishes loading and opens a new design.
        // You can save designId for future use.
      },
      onDesignPublish: ({ exportUrl, designId }) => {
        // Triggered when design is published to an image.
        // Save the image to your server as the exportUrl will expire shortly.
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", exportUrl);
        xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
        xhr.onload = () => {
          blob = xhr.response;//xhr.response is now a blob object
          setTimeout(() => {
            blob.lastModifiedDate = new Date();
            blob.name = 'Diseño en canva';
            blob.canva_id = designId;
            blob.file = blob;
            this.setState({
              relation: relation
            });
            this.setState({
              uploadingCanvaFile: true,
            });
            this.props.uploadFileRequest(blob)
          }, 2000);
        }

        xhr.send();
      },
      onDesignClose: () => {
        // Triggered when editor is closed.
        console.log('Cerró canva');
      },
    });
  }

  handleInputChange(event) {
    const target = event.target;
    this.setState({
      [target.name]: target.value
    });
  }

  handleCanvaClose = e => {
    this.setState({
      canvaModalVisible: false
    });
  };

  // Change status for view alert of min cpm
  showAlertCmpMin = (difference, prevDiference) =>{
    if(difference > 0){
      this.setState({ previuStatusCpm: true });
      this.setState({ viewAlert: true });
    } else if((this.state.previuStatusCpm && difference <= 0) || prevDiference){
      this.setState({ previuStatusCpm: false});
      this.setState({ viewAlert: true });
      setTimeout(() => {
        if(!this.state.previuStatusCpm){
          this.setState({ viewAlert: false });
          //this.setState({ previuStatusCpm: true});
        }
      }, 2000);
    }
  }

  showCanvaModal = (relation) => {
    if (this.props.displays[0].resolution_height && this.props.displays[0].resolution_width) {
      this.setState({
        width: this.props.displays[0].resolution_width,
        height: this.props.displays[0].resolution_height,
      });
    }
    this.setState({
      canvaModalVisible: true,
      relation: relation
    });
  };

  cloneAllSpots = (relation) => {
    this.props.cloneSpotsRelationScreen(relation.promised_shows)
    for (var i = 0; i < this.props.customContents.length; i++) {
      this.props.updateImpressionsDisplay( this.props.customContents[i].displayId,  this.props.customContents[i].relationId, relation.promised_shows);
    }
  };

  screenDetail = (id, currency, invoice_issuing_country, external_dsp_id) => {
    // Update currenDisplay for audience data
    this.props.getDisplayInfoRequest(id, null, currency, invoice_issuing_country, external_dsp_id);
    this.setState({
      screenDetail: true,
    });
  }
  okScreenDetail = e => {
    this.setState({
      screenDetail: false,
    });
  };
  cancelScreenDetail = e => {
    this.setState({
      screenDetail: false,
    });
  };

  showCamTrafficModal = () => {
    this.setCamlyticsTokens();
    this.setState({
      visibleCamTrafficView: true,
    });
  }

  okCamTrafficModal = e => {
    this.setState({
      visibleCamTrafficView: false,
    });
  };

  cancelCamTrafficModal = e => {
    this.setState({
      visibleCamTrafficView: false,
    });
  };

  showCamScreenModal = () => {
    this.setCamlyticsTokens();
    this.setState({
      visibleCamScreenView: true,
    });
  }

  okCamScreenModal = e => {
    this.setState({
      visibleCamScreenView: false,
    });
  };

  cancelCamScreenModal = e => {
    this.setState({
      visibleCamScreenView: false,
    });
  };

  showAudienceModal = () => {
    this.setCamlyticsTokens();
    this.setState({
      visibleAudienceView: true,
    });
  }

  okAudienceModal = e => {
    this.setState({
      visibleAudienceView: false,
    });
  };

  cancelAudienceModal = e => {
    this.setState({
      visibleAudienceView: false,
    });
  };

  setCamlyticsTokens() {
    // Set live view cam and traffic
    var baseLiveViewUrl = 'https://cloud.camlytics.com/widget/live_view/';
    // var baseReportUrl = 'https://cloud.camlytics.com/widget/report/';
    if (this.props.displays[0] && this.props.displays[0].camlytics_tokens) {
      var camlyticsTokens = JSON.parse(this.props.displays[0].camlytics_tokens);
      if (camlyticsTokens && camlyticsTokens.cam_traffic) {
        this.setState({cam_traffic: baseLiveViewUrl + camlyticsTokens.cam_traffic});
      }
      if (camlyticsTokens && camlyticsTokens.cam_display) {
        this.setState({cam_display: baseLiveViewUrl + camlyticsTokens.cam_display});
      }
    }
  }

  showWarningNoAutomateModal = () => {
    this.setState({visibleWarningNoAutomateView: true});
  }

  okWarningNoAutomateModal = e => {
    this.setState({visibleWarningNoAutomateView: false});
  };

  cancelWarningNoAutomateModal = e => {
    this.setState({visibleWarningNoAutomateView: false});
  };

  handleContent = (value, content) => {
    const defaultContentId = this.props.defaultContent.id;
    let newContent = {...content, name: value};
    if((!content || (content && content.id === defaultContentId )) && !this.props.editCampaignActive) {
      this.props.updateDefaultContent(newContent);
    }
    else {
      const customContents = this.props.customContents;
      customContents.map(c => {
        if (c.content && (c.content.id === content.id)){
          this.props.updateContentName(c.relationId, content, value, 'single');
        }
        return
      })
    }
  }

  handleIsCampaignsPausedToggle = (isCampaignPaused, relation) => {
    const active = isCampaignPaused ? 0 : 1;
    this.props.setActive(relation.relationId, active , 'single')
  }

  getTooltipAudience = (camera) => {
    const { t } = this.props;
    return camera ? t('Potential reach in real time of the screen based on the measurement data with cameras, together with the dates and rules selected. Remember to optimize your campaign based on audience data. Also remember that the greater the number of spots, the greater impression you can achieve.') :
                    t('Audience data provided by the media. They are impression based on third-party measurement data. Remember to optimize your campaign based on audience data. Also remember that the more spots, the more impression you can achieve.')
  }

  getTooltipImpacts = () => {
    const { t } = this.props;
    return t("Real-time screen impacts, shows the number of people who will see your spot or group of spots based on camera measurement data, along with dates, selected rules, and investment made. Remember that the greater the investment, the greater the impact it will generate.")
  }

  getAmountAudience = (relation, isImpacts) => {
    const { audience, displays } = this.props;
    if(!audience || !audience.displays_with_audience) return null
    const amountAudience = audience.displays_with_audience.find(element => element.relation_id === relation.relationId) || audience.displays_with_audience.find(element => element.id === displays[0].id)
    if (isImpacts) return (amountAudience && amountAudience.impacts) || null
    return  (amountAudience && amountAudience.audience) || null
  }

  getNextVolumeDiscount(t, displays, date_from, date_to){
    // const {t} = this.props
    let diffDay = moment(date_to).diff(date_from, "days") + 1;
    let nextDiscount
    let listDiscount = displays.rank_discounts
    if(listDiscount === undefined || (listDiscount && listDiscount.length == 0))
     return <></>

    listDiscount.forEach( function(discount) {
      if(diffDay < discount.min_range && !nextDiscount)
        nextDiscount = discount.min_range - diffDay
    })

    if (!nextDiscount)
      return <></>

    return <>
      <span style={{fontSize: '18px'}}>
        <TagsDiscount
          display={displays}
          currency={displays.price_currency}
          color="#faad14"
          />
      </span>
      <span className="textDiscount">
        {t('Increase n_diff_discount your campaign to access higher discounts').replace('n_diff_discount', nextDiscount + " " + (nextDiscount > 1 ? t('days'):t('day')))}
      </span>
    </>
  }

  render() {
    const { t,
      i18n,
      user
      ,date_from,
      date_to,
      editCampaignActive,
      currentCampaign,
      arrContentChanged,
      allDisplaysAreOwn,
      permissionsTransferSpots,
      transferModalVisible,
      client } = this.props;

    const defaultContent = { ...this.props.defaultContent };
    /*TO DO: Create redux with campaignPending */
    const campaign = currentCampaign;

    const campaignPending = campaign ? (campaign.payment_status === "pending" ||
      campaign.payment_status === "cancelled" ||
      campaign.payment_status === "rejected" ||
      campaign.payment_status === "cancelledin_mediation" ||
      campaign.payment_status === "charged_back" ||
      campaign.payment_status === "in_process") : false;

    // Extract display IDs from the displays array
    const displayIds = this.props.displays.map(d => d.id);

    const customContents = this.props.customContents.filter(c =>
      displayIds.includes(c.displayId)
    );
    const { RangePicker } = DatePicker;

    if (this.props.displays[0].camlytics_tokens) {
      var camlyticsTokens = JSON.parse(this.props.displays[0].camlytics_tokens);
    }
    var workingHoursList = []
    if (this.props.displays[0].working_hours) {
      getWorkingHoursList(t, this.props.displays[0].working_hours, workingHoursList)
     }
    var sliderSettings = {
      arrows: true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SliderNextArrow />,
      prevArrow: <SliderPrevArrow />
    };

    // Add new custom contents to customContents array
    this.props.displays
        .filter(d => !displayIds.includes(d.id)) // Filter out elements not present in displayIds
        .forEach(d => customContents.push({
          content: defaultContent,
          relationId: customContents[i].relationId,
          displayId: d.id,
          promised_shows: customContents[i].promised_shows > 0 ? customContents[i].promised_shows : Math.trunc((parseFloat(this.props.amountBudget) / this.props.cart.length / this.props.customContents.length ) / (this.props.displays[0].smart_campaign_cpm/1000)),
          fill_screen: false,
          rules: [],
        }));

    const typePreviewData = [];

    if (customContents.length > 0) {
      // search the id of customContents that not include in this.props.customContents
      customContents.forEach(customContent => {
        if (!this.props.customContents.find(c => c.relationId === customContent.relationId)) {
          this.props.addCustomContent(customContent.content, customContent.displayId, customContent.relationId, this.props.displays.length > 1 ? ('multiple') : ('single'));
        }
      });
      if (this.props.displays.length === 1) {
        for (var i = 0; i < customContents.length; i++) {
          // indice_relation define if is Smart List
          typePreviewData.push(
            {
              relationId: customContents[i].relationId,
              indice_relation: customContents[i].indice_relation,
              active: customContents[i].active,
              fill_screen: customContents[i].fill_screen,
              isContentMixed: false,
              promised_shows: customContents[i].promised_shows,
              content: get(customContents, '[' + i + '].content') || defaultContent,
              promised_shows_subsidized: customContents[i].promised_shows_subsidized ? customContents[i].promised_shows_subsidized : 0,
              subsidized_price: customContents[i].subsidized_price == undefined ? false: customContents[i].subsidized_price,
              rules: customContents[i].rules && customContents[i].rules.length > 0 ? customContents[i].rules : []
            }
          )
        }
      } else {
        let listAddCustomContents = displayIds.filter(d => !this.props.customContents.find(c => c.displayId === d))
        listAddCustomContents.forEach(displayId => {
          let customContentAux = {
            content: defaultContent,
            relationId: displayId + '_' + (this.props.customContents.length + random(99999999)),
            displayId: displayId,
            promised_shows: Math.trunc((parseFloat(this.props.amountBudget) / this.props.cart.length ) / (this.props.displays[0].smart_campaign_cpm/1000)),
            fill_screen: false,
            rules: [],
          }
          this.props.addRelationDisplay(customContentAux.displayId, customContentAux.relationId, customContents.promised_shows)
         });
        typePreviewData.push(
          {
            relationId: getRelationId(customContents[0].relationId),
            indice_relation: customContents[0].indice_relation,
            active: customContents[0].active,
            fill_screen: customContents[0].fill_screen,
            isContentMixed: false,
            content: get(customContents, '[' + 0 + '].content') || defaultContent,
            subsidized_price: customContents[0].subsidized_price == undefined ? false: customContents[0].subsidized_price,
            promised_shows_subsidized: customContents[0].promised_shows_subsidized ? customContents[0].promised_shows_subsidized : 0,
          }
        )
      }
    } else {
      for (var j = 0; j < this.props.displays.length; j++) {
        this.props.addRelationDisplay(this.props.displays[j].id, this.props.displays[j].id + '_' + (customContents.length + random(99999999)), Math.trunc((parseFloat(this.props.amountBudget) / this.props.cart.length ) / (this.props.displays[j].smart_campaign_cpm/1000)))
      }
      typePreviewData.push(
        {
          isFillMixed: false,
          fill_screen: false,
          isContentMixed: false,
          promised_shows: Math.trunc((parseFloat(this.props.amountBudget) / this.props.cart.length ) / (this.props.displays[0].smart_campaign_cpm/1000)),
          content: defaultContent,
          subsidized_price: false,
          rules: [],
        }
      )
    }


    // Variables to rangepicker
    const dateFormat = 'DD/MM/YYYY';
    const dates = [
      moment(this.props.date_from, 'YYYY-MM-DD'),
      moment(this.props.date_to, 'YYYY-MM-DD'),
    ]
    const disabledDate = current => {
      if (!dates || dates.length === 0) {
        return false;
      }
      return current && current < dates[0] || current && current > dates[1];
    };

    if (typePreviewData.rules && typePreviewData.rules.length > 0) {
      this.state.ruleActive = true;
      for (var i = 0; i < typePreviewData.rules.length; i++) {
        if (typePreviewData.rules[i].conditions && !this.state.rules[i]) {
          this.state.rules.push({"conditions":[]})
        }

        var dateRule = typePreviewData.rules[i].conditions.find(rule => rule.type === 'date');
        if (dateRule && this.state.rules[i] && !this.state.rules[i].conditions.find(rule => rule.type === 'date')) {
          remove(this.state.rules[i].conditions, function(condition) {
            return condition.type === 'date';
          });
          this.state.rules[i].conditions.push(dateRule)
        }

        var dayRule = typePreviewData.rules[i].conditions.find(rule => rule.type === 'day');
        if (dayRule && this.state.rules[i] && !this.state.rules[i].conditions.find(rule => rule.type === 'day')) {
          var temporaryArrayDays = {
            "type":"day",
            "value": dayRule.value
          }
          this.state.rules[i].conditions.push(temporaryArrayDays)
          temporaryArrayDays = {};
        }

        var hourRule = typePreviewData.rules[i].conditions.find(rule => rule.type === 'hour');
        if (hourRule && this.state.rules[i] && !this.state.rules[i].conditions.find(rule => rule.type === 'hour')) {
          this.state.arrayTime = clone(hourRule);
          remove(this.state.rules[i].conditions, function(condition) {
            return condition.type === 'hour';
          });
          this.state.rules[i].conditions.push(this.state.arrayTime)
        }
      }
    }

    const uploadProps = {
      name: "file",
      accept: "video/*,image/*,video/mp4,video/x-m4v",
      action: `${apiUrl}/contents`,
      multiple: true,
      showUploadList: false,
      data: file => ({
        name: createNameNewContent(client, customContents)
      }),
      beforeUpload(file, fileList) {
        const isLt2M = file.size / 1024 / 1024 < 50;
        if (!isLt2M) {
          message.error(this.props.t('The file cannot be larger than 50MB!'), 10);
        }
        return isLt2M;
      },
      headers: { authorization: `Bearer ${this.props.token}` },
    };

    var totalPromise = 0
    for (var i = 0; i < customContents.length; i++) {
      totalPromise = totalPromise + customContents[i].promised_shows
    }

    return (
      <ScreenPreviewHolder>
        <Card className="screenTypeCard">
          {this.state.isHidden ? (
            <div>
              {this.props.displays.length === 1 ? (
                <div style={{ position: "absolute", width: "94%" }}>
                  {this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved' ?
                    (
                      <Upload
                        {...uploadProps}
                        onChange={(info) => this.onChangeContentState(info, null, this.props.displays.length > 1 ? ('multiple') : ('single'))}
                      >
                        <Tooltip title={t('Add a Spot for this screen. (In case they meet the same rules, it will print once each)')}>
                          <Button
                            shape="circle"
                            style={{position: "absolute", right: "30px", top: "0px", zIndex: 1 }}
                            onClick={() => this.addRelationDisplay(customContents, "newRelation")}
                          >
                            <span>
                              <Icon style={{ fontSize: "14px" }} type="plus" />
                            </span>
                          </Button>
                        </Tooltip>
                      </Upload>
                    ) : (
                      <Tooltip title={t('Add a Spot for this screen. (In case they meet the same rules, it will print once each)')}>
                        <Button
                          shape="circle"
                          style={{position: "absolute", right: "30px", top: "0px", zIndex: 1 }}
                          onClick={() => this.addRelationDisplay(customContents)}
                        >
                          <span>
                            <Icon style={{ fontSize: "14px" }} type="plus" />
                          </span>
                        </Button>
                      </Tooltip>
                    )
                  }
                </div>
              ) : null}
              <Slider ref={slider => (this.slider = slider)} {...sliderSettings}>
                  {typePreviewData.map((relation, index) => (
                  <div key={index}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="titleTypeSize">
                      {this.props.displays.length === 1 && typePreviewData.length > 1 ? (
                        <Button shape="circle" style={{position: "absolute", left: "15px", top: "-15px", zIndex: 1 }}>
                          <span>
                            {index + 1}
                          </span>
                        </Button>
                      ) : null}
                      {this.props.displays.length > 1 ? (
                        <span>
                        {this.props.displays.length} {t('screens with')}{" "}
                          {relation.isContentMixed && <b>{t('mixed content')}. </b>}
                        </span>
                      ) : null}
                        {t('Resolution')}:{" " + this.props.typeSize
                      }
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} className="dataScreenColumn">
                      <span>
                        <div style={{marginBottom: "10px"}}>
                          {this.props.displays.length > 1 && Math.floor(relation.content.length / 1000) * 1000 > (this.props.displays[0].slot_length) && this.props.programmatic === 'traditional' ? (
                            <Tag color="#f74f48">{t('Exceeded on some screen')}</Tag>
                          ) : (
                          this.props.displays.length > 1 && 11000 < relation.content.length && this.props.programmatic === 'programmatic' ? (
                            <Tag color="#f74f48">{t('Exceeded on some screen')}</Tag>
                          ) : null )
                          }
                          {this.props.displays.length === 1 && this.props.programmatic === 'programmatic' && ((this.props.displays[0].slot_length + 1000) < relation.content.length) ? (
                            <Tooltip
                              placement="left"
                              title={
                                <span>{t('In programmatic the ads should not exceed the maximum time of the advertising space on the screen, otherwise they could be cut off in this time')}</span>
                              }>
                              <Tag color="#f74f48">
                                  <span>
                                    {t('Exceeded in')} {Math.floor((relation.content.length - this.props.displays[0].slot_length) / 1000)} {t('secs')} <Icon type="question-circle"/>
                                  </span>
                              </Tag>
                              <br />
                            </Tooltip>
                          ) : (
                            this.props.displays.length === 1 && this.props.programmatic === 'traditional' && Math.floor(relation.content.length / 1000) * 1000 > this.props.displays[0].slot_length ? (
                              <Tooltip
                                placement="left"
                                title={
                                  <span>{t('If you do not change the ad for a shorter one, it will add approximately')} ${Math.round((this.props.displays[0].price_per_day * (Math.floor(relation.content.length / 1000) * 1000) / this.props.displays[0].slot_length - this.props.displays[0].price_per_day) * 100) / 100} {t('per day for this screen.')}</span>
                                }>
                                <Tag color="#f74f48">
                                  <span>
                                    {t('Exceeded in')} {Math.floor((relation.content.length - this.props.displays[0].slot_length) / 1000)} {t('secs')} <Icon type="question-circle"/>
                                  </span>
                                </Tag>
                                <br />
                              </Tooltip>
                            ) : null )
                          }
                          {this.props.displays.length === 1 ? (
                            <a>
                              <b><span className="titelScreen">{this.props.displays[0].name} ({this.props.displays[0].resolution_width}x{this.props.displays[0].resolution_height})</span></b>
                              {" "}
                              {this.props.displays[0].use_cms === 0 ? (
                                <Tooltip placement="bottomLeft" title={t('Non-automated screen. Click to see detail')}>
                                  <Button className="screenWarningNoAutomateView" shape="circle" size='small' onClick={() => this.showWarningNoAutomateModal()}>
                                    <Icon type="warning" theme="outlined" style={{color: "#ffc107"}}/>
                                  </Button>
                                </Tooltip>
                              ) : null}
                              <Modal
                                title={t('Non-automated screen')}
                                visible={this.state.visibleWarningNoAutomateView}
                                onOk={this.okWarningNoAutomateModal}
                                onCancel={this.cancelWarningNoAutomateModal}
                              >
                                <p style={{margin: "25px"}}>{t('This screen does not have the real-time print notice. Therefore, print reports may take up to 48 hours to be reflected.')}</p>
                              </Modal>
                            </a>
                          ) : null}
                        </div>
                        <span style={{marginRight: "5px"}}>
                          <Tooltip title={!relation.fill_screen ? t('Expand ad') : t('Return original resolution')}>
                            <Button
                              shape="circle"
                              onClick={() =>
                                this.props.displays.map(d =>
                                  this.props.toggleFillScreen(
                                    d.id,
                                    relation.relationId,
                                    !relation.fill_screen,
                                    this.props.displays.length > 1 ? ('multiple') : ('single')
                                  )
                                )
                              }
                            >
                              {!relation.fill_screen ? (
                                <Icon
                                  type="fullscreen"
                                />
                              ) : (
                                <Icon
                                  type="fullscreen-exit"
                                />
                              )}
                            </Button>
                          </Tooltip>
                        </span>
                        <span style={{marginRight: "5px", display: "inline-block"}}>
                          <Upload
                            {...uploadProps}
                            disabled = {this.state.uploadingFile}
                            onChange={(info) => this.onChangeContentState(info, relation, this.props.displays.length > 1 ? ('multiple') : ('single'), this)}
                            >
                            <Tooltip title={t('Change ad for this')+ ((i18n.language == 'es' || i18n.language == 'es_ar') && this.props.displays.length === 1 ? "" : ((i18n.language == 'es' || i18n.language == 'es_ar') ? "s" : "")) + (this.props.displays.length === 1 ? (typePreviewData.length > 1 ? (' ' + t('relationship')) : (' ' + t('screen'))) : (' ' + t('screens')))}>
                              <Button
                                shape="circle"
                                disabled = {this.state.uploadingFile}
                              >
                                {this.state.uploadingFile ? <Icon type="loading"/> : <Icon type="interaction"/>}
                              </Button>
                            </Tooltip>
                          </Upload>
                        </span>
                        {this.props.displays.length === 1 ? (
                          <span>
                            {relation.content.canva_id ? (
                              <span style={{marginRight: "5px"}}>
                                <Tooltip placement="top" title={t('Ad created in canva, you can edit and change it for this screen or relationship.')}>
                                  <Button
                                    shape="circle"
                                    onClick={() => this.editCanvaDesign(relation)}
                                  >
                                    <img
                                      src={canvaLogoEdit}
                                      style={{height: "20px", marginTop: "-3px", display: "inherit"}}
                                      alt=""
                                    />
                                  </Button>
                                </Tooltip>
                              </span>
                            ) : null}
                            <span style={{marginRight: "5px"}}>
                              <Tooltip placement="top" title={t('Create new ad in canva for this relationship')}>
                                <Button
                                  shape="circle"
                                  onClick={() => this.showCanvaModal(relation)}
                                >
                                  <img
                                    src={canvaLogoAdd}
                                    style={{height: "20px", marginTop: "-3px", display: "inherit"}}
                                    alt=""
                                  />
                                </Button>
                              </Tooltip>
                            </span>
                            <span style={{marginRight: "5px"}}>
                              <Tooltip placement="top" title={t('Screen detail')}>
                                <Button
                                  shape="circle"
                                  onClick={() => this.screenDetail(this.props.displays[0].id,
                                                                   this.props.displays[0].price_currency,
                                                                   this.props.campaignInvoiceIssuingCountry,
                                                                   this.props.external_dsp_id)}
                                >
                                  <Icon
                                    type="info-circle"
                                  />
                                </Button>
                              </Tooltip>
                            </span>
                            <Modal
                              title={t('Screen detail')}
                              visible={this.state.screenDetail}
                              onCancel={this.cancelScreenDetail}
                              style={{top: "10px"}}
                              footer={[
                                <Button key="back" onClick={this.okScreenDetail}>
                                  OK
                                </Button>,
                              ]}
                              width={1200}
                            >
                            <ScreenContainer
                              display={this.props.displays[0]}
                              screenDetail={this.state.screenDetail}
                              popup={true}
                              campaignInvoiceIssuingCountry={this.props.campaignInvoiceIssuingCountry}
                              editCart={false}
                            />
                            </Modal>
                          </span>
                        ) : null}
                      </span>
                      {this.props.displays.length === 1 ? (
                        <span style={{marginRight: "5px"}}>
                          <Fragment>
                            {typePreviewData.length > 1 ? (
                              <span>
                                {!this.props.editCampaignActive || 
                                 (this.props.editCampaignActive && relation.relationId.toString().search('_') > 0) ||
                                 campaignPending ? (
                                  <span style={{marginRight: "5px"}}>
                                    <Tooltip placement="top" title={t('Remove spot')}>
                                      <Button
                                        shape="circle"
                                        onClick={() =>
                                          this.removeRelationFromDisplayView(this.props.displays[0].id, relation.relationId, customContents)
                                        }
                                      >
                                        <Icon
                                          type="close-square"
                                        />
                                      </Button>
                                    </Tooltip>
                                  </span>
                                ) : null }
                              </span>
                            ) : null}
                            {this.props.editCampaignActive && this.props.currentCampaign.status === 'ready' ?
                              null
                            : <span style={{marginRight: "5px"}}>
                              <Tooltip placement="top" title={this.props.cart.length < 2 ? t('To delete need at least two screens in the campaign') : t('Remove screen')}>
                                <Button
                                  shape="circle"
                                  disabled={this.props.cart.length < 2}
                                  onClick={() =>
                                    this.props.removeRelationFromCustomContents(this.props.displays[0].id) &&
                                    this.props.removeDisplayFromCart(this.props.displays[0].id)
                                  }
                                >
                                  <Icon
                                    type="delete"
                                  />
                                </Button>
                              </Tooltip>
                              </span>
                            }
                            { permissionsTransferSpots ?
                              <>
                                <span style={{marginRight: "5px", display: "inline-block"}}>
                                      <Tooltip placement="top" title={`${t("Transfer of spots")}`}>
                                        <Button
                                          shape="circle"
                                          onClick={() => this.showTransferModal(relation.relationId)}
                                        >
                                          <Icon type="transaction"/>
                                        </Button>
                                      </Tooltip>
                                </span>
                                <Modal
                                  title={t("Transfer of spots")}
                                  visible={transferModalVisible === relation.relationId}
                                  onCancel={this.showTransferModal}
                                  footer={null}
                                  width={1200}
                                  style={{ top: "10px" }}
                                >
                                  <ImpressionManagementComponent
                                    currentCampaign = {this.props.currentCampaign}
                                    customContents = {this.props.customContents}
                                    companySelected = {this.props.displays[0].company ? this.props.displays[0].company : null}
                                    reportSingleData = {this.props.reportSingleData}
                                    transferModalVisible = {transferModalVisible}
                                    cart= {this.props.cart}
                                    showTransferModal = {this.showTransferModal}
                                    updateImpressionsDisplay = {this.props.updateImpressionsDisplay}
                                    creditAvailableOnTransferredImpressions = {this.props.creditAvailableOnTransferredImpressions}
                                    updateCreditAvailable= {this.props.updateCreditAvailable}
                                  />
                                </Modal>
                              </>
                              : null}
                            <br/>
                            <div>
                              {this.props.programmatic === 'programmatic' ? (
                                <div style={{marginTop: 10, marginBottom: 10 }}>
                                  <Popover
                                    placement="left"
                                    content={
                                      <div>
                                        {t('Consumed')}{" "}
                                        <span style={{color: Colors.primary}}>
                                          {(this.props.reportSingleData.report && this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id) ?
                                          this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id).shows :
                                          0)}
                                        </span><br />
                                        {t('To consumed')}{" "}
                                        <span style={{color: Colors.primary}}>
                                          {((relation.promised_shows + relation.promised_shows_subsidized ) - (this.props.reportSingleData.report && this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id) ?
                                          this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id).shows :
                                          0))}
                                        </span><br />
                                        {t('Total material')}{" "}
                                        <span style={{color: Colors.primary}}>
                                          {(this.props.reportSingleData.report && this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id) ?
                                          this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id).shows :
                                          0) + (relation.promised_shows - (this.props.reportSingleData.report && this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id) ?
                                          this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id).shows :
                                          0))}
                                        </span><br />
                                        {this.state.totalSubsidizePromisedShowsAllRelations > 0 ? (
                                          <>
                                            {t('Total material subsidized')}{" "}
                                            <span style={{color: Colors.primary}}>
                                              {relation.promised_shows_subsidized}
                                            </span><br />
                                            {t('Total subsidize')}{" "}
                                            <span style={{color: Colors.primary}}>
                                              {this.state.totalSubsidizePromisedShowsAllRelations}
                                            </span><br />
                                          </>
                                        ): null}
                                        {t('Total display')}{" "}
                                        <span style={{color: Colors.primary}}>
                                          {this.calculatePromisedShows(this.props.displays[0].id)}
                                        </span>
                                      </div>
                                    }
                                    title={t('Impressions details')}
                                  >
                                    <Progress
                                      percent={Math.round(((relation.promised_shows + relation.promised_shows_subsidized)* 100 / (this.state.totalPromisedShowsAllRelations + this.state.totalSubsidizePromisedShowsAllRelations) * 100) / 100)}
                                      successPercent={Math.round(((this.props.reportSingleData.report && this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id) ?
                                      this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id).shows :
                                      0) * 100 / (this.state.totalPromisedShowsAllRelations + this.state.totalSubsidizePromisedShowsAllRelations) * 100) / 100)}
                                    />
                                  </Popover>
                                  {this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved' ?
                                    null : (
                                      <Tag color="blue">
                                        <Icon
                                          style={{ marginRight: "5px" }}
                                          type="images"
                                        />{"$ " + Math.round(this.props.displays[0].smart_campaign_cpm / 1000 * 1000) / 1000 + " " + t('per print')}
                                      </Tag>
                                    )
                                  }
                                  <Tag color="blue" style={{ marginBottom: "7px" }}>
                                    <Icon
                                      style={{ marginRight: "5px", marginBottom: "5px" }}
                                      type="desktop"
                                    />
                                      <span>
                                        {this.GetImpressionsToShowPerDay(this.props.date_from, this.props.date_to, relation.promised_shows, relation.rules) === 0 ? 0 : Math.round(relation.promised_shows / this.GetImpressionsToShowPerDay (this.props.date_from, this.props.date_to, relation.promised_shows, relation.rules))} {t('Prints')}/{t('day')}
                                      </span>
                                  </Tag>
                                  {(relation.promised_shows && this.getAmountAudience(relation,'impacts')) &&
                                    <>
                                      <Tooltip
                                        placement="left"
                                        title= {t('(CPM) cost per thousand impression')}>
                                        <Tag color="blue">
                                          CPM
                                          { arrContentChanged.filter( item => item.relationId === relation.relationId ).length > 0  && this.props.loadingAudience ? <Icon type="loading" /> :
                                            <span>
                                              &nbsp;$ {getCPM(calculateValueByCpm(
                                                this.props.displays[0].smart_campaign_cpm,
                                                relation.promised_shows, "calculate_price"),
                                                this.getAmountAudience(relation, 'impacts'))
                                              }
                                            </span>
                                          }
                                        </Tag>
                                      </Tooltip>
                                    </>
                                  }
                                  {relation.promised_shows_subsidized ?
                                    <Tag color="orange">
                                      <Icon
                                        style={{ marginRight: "5px" }}
                                        type="desktop"
                                      />
                                        <span>
                                          {Math.round(relation.promised_shows_subsidized / this.GetImpressionsToShowPerDay (this.props.date_from, this.props.date_to, relation.promised_shows_subsidized, relation.rules))} imp {t('subsidized')}/{t('day')}
                                        </span>
                                    </Tag>
                                    : null
                                  }
                                  <br />
                                  <Tooltip
                                    placement="left"
                                    title={
                                      <span>{this.GetImpressionsToShowPerDay(this.props.date_from, this.props.date_to, relation.promised_shows, relation.rules)} {t('day')}s</span>
                                    }>
                                    {this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved' ? (
                                      <span style={{ color: Colors.primary, fontSize: "12px" }}>{t('Remaining to consume')}:<br /> </span>
                                    ) : null }
                                    <InputNumber
                                      type="number"
                                      style={{width: 100, marginRight: 3, marginTop: 10, color: "#000000b8"}}
                                      min={0}
                                      disabled={this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved'}
                                      max={this.calculateMax(this.props.displays[0].id, relation)}
                                      value={
                                        relation.promised_shows - (this.props.reportSingleData && this.props.reportSingleData.report ? (
                                          this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id) ?
                                          this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id).shows :
                                        0) : 0 )
                                      }
                                      onChange={e => {
                                      this.props.displays.map(d =>
                                        this.updateImpressionsDisplay(
                                          d.id,
                                          relation,
                                          e + (this.props.reportSingleData && this.props.reportSingleData.report ?
                                                (this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id) ?
                                                this.props.reportSingleData.report.find(element => element.display === this.props.displays[0].id && element.content === relation.content.id).shows :
                                                0) : 0 )
                                        )
                                      )}
                                    }/>
                                    {this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved' ? null : <Icon type="arrow-right" theme="outlined" style={{color: '#c8c8c8'}}/>}
                                  </Tooltip>
                                  {(!this.props.editCampaignActive || this.props.currentCampaign.payment_status !== 'approved') && (
                                    <>
                                      <b className="amountCampaignPerDisplay" style={{marginLeft: 3}}>
                                        <NumberFormat
                                          tax_include={false}
                                          value={calculateValueByCpm(this.props.displays[0].smart_campaign_cpm,
                                                                      relation.promised_shows, "calculate_price")
                                                }
                                          currency={this.props.editCampaignActive ? this.props.currentCampaign.currency:
                                                                                    this.props.currency}
                                        />

                                      </b>
                                      <Tooltip
                                        placement="bottomLeft"
                                        title={t('Setting this value in all screen and spot')}>
                                        <Button
                                            className="button cloneAllSpotButton"
                                            Tooltip="pronando"
                                            style={{marginLeft: "10px"}}
                                            onClick={() => this.cloneAllSpots(relation)}>
                                            {t('Clone spot')}
                                          </Button>
                                        </Tooltip>
                                        <br/>
                                      </>
                                    )}
                                  {this.props.programmatic === 'programmatic' &&
                                  <>
                                    {user && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn)?
                                        <div style={{marginBottom: 10, marginTop: 10}}>
                                          <InputNumber
                                              type="number"
                                              style={{width: 100, marginRight: 3}}
                                              min={0}
                                              max={999999999}
                                              value={relation.promised_shows_subsidized}
                                              onChange={ e => {
                                              this.updateImpressionsSubsidizeDisplay(
                                                relation.relationId,
                                                e
                                              )}}
                                          />
                                          <Tooltip placement="bottomLeft" title={t('These impressions are reflected as subsidize in the campaign')}>
                                            <Icon
                                            style={{ fontSize: "20px", marginLeft: "10px", color: "#f7d455", verticalAlign: "middle"}}
                                            type="warning"
                                            />
                                          </Tooltip>
                                          &emsp;
                                          {t('Subsidize impression')}
                                        </div> : null
                                    }
                                    <CSSEffect>
                                      <CSSTransition in={this.state.viewAlert} unmountOnExit timeout={100} classNames="my-node">
                                        <>
                                          {(calculateValueByCpm(this.props.displays[0].smart_campaign_cpm, totalPromise, "difference_cpm_price") > 0) ?
                                              <Alert
                                                className="alertCPM"
                                                type="warning"
                                                description= {t("Avoid getting your ad disapproved, add %deference_cpm impressions to cover the CPM!").replace(
                                                            "%deference_cpm", calculateValueByCpm(this.props.displays[0].smart_campaign_cpm, totalPromise, "compare_cpm_impresion"))}
                                              />:
                                              <>
                                                {this.state.previuStatusCpm == false ?
                                                  <Alert
                                                    className="alertCPM"
                                                    type="success"
                                                    description= {calculateValueByCpm(this.props.displays[0].smart_campaign_cpm, totalPromise, "compare_cpm_impresion") == 0 ?
                                                                  t("Congratulations! You reached the minimum CPM"): t("Congratulations! You exceeded the minimum CPM")}
                                                  />:null
                                                }
                                              </>
                                              }
                                        </>
                                      </CSSTransition>
                                    </CSSEffect>
                                  </>}
                                </div>
                              ) : null }
                              {relation.indice_relation === null &&
                               ((this.props.editCampaignActive
                                && this.props.currentCampaign.payment_status !== 'pending'
                                && this.props.currentCampaign.payment_status !== 'cancelled'
                                && user
                                && hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn) ?
                                (
                                  <div style={{ marginBottom: 10, marginTop: 10 }}>
                                    <span>
                                      {t('Pause content')}
                                      &emsp;
                                      <Switch
                                        onChange={(value) => this.handleIsCampaignsPausedToggle(value, relation)}
                                        checked={relation.active === 0 ? true : false}
                                        size="small"
                                      />
                                    </span>
                                  </div>
                                ): null)}
                              {!this.props.editCampaignActive ?
                                <>
                                  {user && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn) ?
                                      <div style={{marginBottom: 10, marginTop: 10}}>
                                          {t('Subsidize price of this screen')}
                                          &emsp;
                                          <Switch
                                            checked = {relation.subsidized_price}
                                            disabled = {this.props.programmatic === 'traditional' && typePreviewData.length > 1}
                                            onClick={() =>
                                              this.props.updateSubsidizedPrice(
                                                relation.relationId,
                                                !relation.subsidized_price,
                                                'single'
                                              )
                                            }
                                            size="small"
                                          />
                                        <Tooltip placement="bottomLeft" title={t('By enabling this option, the screen is subsidize, affecting the price of the campaign')}>
                                          <Icon
                                            style={{ fontSize: "20px", marginLeft: "10px", color: "#f7d455", verticalAlign: "bottom"}}
                                            type="warning"
                                          />
                                        </Tooltip>
                                      </div>:
                                    null}
                                </>:<>
                                  {relation.subsidized_price ?
                                    (relation.promised_shows != 0 ?
                                      <span className="ant-tag ant-tag-orange" style={{marginLeft: "5px", fontSize: "10px", marginTop: "5px"}}>
                                        {t('Subsidized')}
                                      </span>
                                      : // When the user changes the value to zero.
                                        <span className="ant-tag ant-tag-blue" style={{marginLeft: "5px", fontSize: "10px"}}>
                                      {t('Suspended')}
                                    </span>)
                                  :null}
                                </>
                              }
                              {this.props.programmatic === 'programmatic' ||
                               (this.props.programmatic === 'traditional' && user && hasAccessRole(user.roles,[UserRoleEnum.companyMember]))? (
                                <div style={{marginBottom: 10}}>
                                  <Button className="button addRuleButton" onClick={() => this.addRule(relation)} style={{fontSize: "11px" }}>
                                    {relation.rules.length > 0 ? (
                                      t('Add another rule')
                                      ) : t('Add rule') }
                                  </Button>
                                  {relation.rules.length > 0 ? (
                                    <span>
                                      <Button className="button removeAllRulesButton" onClick={() => this.removeAllRules(relation)} style={{fontSize: "11px" }}>
                                        {t('Remove rules')}
                                      </Button>
                                      <Button className="button cloneAllRulesButton" onClick={() => this.cloneAllRules(relation)}>
                                        {t('Clone rules')}
                                      </Button>
                                    </span>
                                  ) : null }
                                  <br />
                                </div>
                              ) : null }
                            </div>
                          </Fragment>
                        </span>
                      ) : null
                      }
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <div className="screenContainer">
                        <Rectangle aspectRatio={this.props.aspectRatio.split(":")}>
                          <div className="screen">
                            {relation.content && relation.content.ready && relation.content.type === 'image' && (
                              <img
                                src={
                                  relation.isContentMixed
                                    ? placeholder
                                    : relation.content && relation.content.ready
                                    ? relation.content.file_thumb.replace(".mp4", "-00001.jpg")
                                    : null
                                }
                                className={
                                  relation.fill_screen
                                    ? "screenPreviewFilled"
                                    : "screenPreview"
                                }
                                alt=""
                              />
                            )}
                            {/* defaultContent.ready is necesary when change default content */}
                            {(this.props.defaultContent.ready &&
                             (relation.content && relation.content.ready && relation.content.type === 'video')) && (
                              <video
                                controls
                                className={
                                  relation.fill_screen
                                    ? "screenPreviewFilled"
                                    : "screenPreview"
                                }
                              >
                                <source src={ relation.content.file } type="video/mp4" />
                              </video>
                            )}
                            {(relation.content && relation.content.id && !relation.content.ready) || !this.props.defaultContent.ready ? (
                              <div className="pulseLoaderContainer">
                                <Spin size="large" spinning />
                              </div>
                            ) : null}
                            {this.props.displays.length === 1 ? (
                              this.props.programmatic === 'programmatic' ? (
                                <Tooltip
                                  placement="top"
                                  title={
                                    <span>{t('Standard time of the programmatic space, an extra could be charged in the case of a longer ad.')}</span>
                                  }>
                                  <div className="time">
                                    {t('Space time')}: {this.props.displays[0].slot_length / 1000} {t('secs')}
                                  </div>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  placement="top"
                                  title={
                                    <span>{t('Standard time of the space of this screen, an extra could be charged in the case of being a longer ad.')}</span>
                                  }>
                                  <div className="time">
                                  {t('Space time')}: {this.props.displays[0].slot_length / 1000} {t('secs')}
                                  </div>
                                </Tooltip>
                              )
                            ) : null}
                          </div>
                        </Rectangle>
                        <div className="cardsContainer">
                          {this.props.displays.length === 1 && camlyticsTokens && camlyticsTokens.cam_traffic ? (
                            <Tooltip placement="bottomLeft" title={t('Camera to traffic')}>
                              <Button className="screenCardCamTrafficView" shape="circle" size='small' onClick={() => this.showCamTrafficModal()}>
                                <Icon type="car" theme="outlined"/>
                              </Button>
                            </Tooltip>
                          ): null}
                          <Modal
                            title={t('Traffic camera for audience control and measurement')}
                            visible={this.state.visibleCamTrafficView}
                            onOk={this.okCamTrafficModal}
                            onCancel={this.cancelCamTrafficModal}
                          >
                            <iframe id="live-view-traffic" src={this.state.cam_traffic} frameBorder="0" scrolling="no" width="100%" height="300px"></iframe>
                          </Modal>
                          {this.props.displays.length === 1 && camlyticsTokens && camlyticsTokens.cam_display ? (
                            <Tooltip placement="bottomLeft" title={t('Camera for certification')}>
                              <Button className="screenCardCamCertification" shape="circle" size='small' onClick={() => this.showCamScreenModal()}>
                                <Icon type="video-camera" theme="outlined"/>
                              </Button>
                            </Tooltip>
                          ): null}
                          <Modal
                            title={t('Camera looking at screen for certification')}
                            visible={this.state.visibleCamScreenView}
                            onOk={this.okCamScreenModal}
                            onCancel={this.cancelCamScreenModal}
                          >
                            <iframe id="live-view-traffic" src={this.state.cam_display} frameBorder="0" scrolling="no" width="100%" height="300px"></iframe>
                          </Modal>
                          {this.getAmountAudience(relation) &&
                            <>
                            <Tooltip placement="bottomLeft" title={this.getTooltipAudience(this.props.displays.length === 1 && camlyticsTokens && camlyticsTokens.cam_display)}>
                              <Button className="screenCardAudit" size='small' onClick={() => this.showAudienceModal()}>
                                {arrContentChanged.filter( item => item.relationId === relation.relationId ).length > 0  && this.props.loadingAudience ? <Icon type="loading" /> :
                                  <>
                                    <Icon type="usergroup-add" theme="outlined"/>
                                    <span>
                                      {this.getAmountAudience(relation)}
                                    </span>
                                  </>
                                }
                              </Button>
                            </Tooltip>
                            </>
                          }
                          {this.getAmountAudience(relation, 'impacts') &&
                            <Tooltip placement="bottomLeft" title={this.getTooltipImpacts()}>
                              <Button className="screenCardAudit" size='small' onClick={() => this.showAudienceModal()}>
                                { arrContentChanged.filter( item => item.relationId === relation.relationId ).length > 0  && this.props.loadingAudience ? <Icon type="loading" /> :
                                  <>
                                    <AimIcon className="aimIcon"/>
                                    <span>
                                      {this.getAmountAudience(relation,'impacts')}
                                    </span>
                                  </>
                                  }
                              </Button>
                            </Tooltip>
                          }
                          <TextArea
                            placeholder={t('Identify your ad...')}
                            maxLength={20}
                            className="displayTextArea"
                            autoSize
                            value={relation.content.name}
                            onChange={e => this.handleContent(e.target.value, relation.content)}
                          />
                          <Modal
                            title={t('Audience')}
                            visible={this.state.visibleAudienceView}
                            onOk={this.okAudienceModal}
                            onCancel={this.cancelAudienceModal}
                            width={1000}
                          >
                            <AudienceSummaryComponentGeneral
                              t= {t}
                              display= {this.props.displays[0]}
                              user={user}
                              campaign={ editCampaignActive ? currentCampaign : null}
                              audienceHoursOfDay = {this.props.audienceHoursOfDay}
                              audienceDaysOfWeek = {this.props.audienceDaysOfWeek}
                              getAudienceHoursOfDayRequest={this.props.getAudienceHoursOfDayRequest}
                              getAudienceDaysOfWeekRequest={this.props.getAudienceDaysOfWeekRequest}
                            />
                          </Modal>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{paddingLeft: 0, paddingRight: 0}}>
                      {this.props.displays.length === 1 ? (
                        <div>
                          {relation.rules.map((rule, index) => (
                            <Card key={index} style={{borderRadius: 10, marginBottom: 10}}>
                              <Tooltip
                                placement="top"
                                title={
                                  <span>{t('Remove rule')}</span>
                                }>
                                <Icon type="close" className="closeRuleButton" onClick={() => this.removeRule(rule, index, relation)}/>
                              </Tooltip>
                              <div style={{marginBottom: 15}}>
                                <RangePicker
                                  onChange={(date, dateString) => this.changeDate(date, dateString, index, relation)}
                                  disabledDate={disabledDate}
                                  defaultValue={[
                                    rule.conditions && rule.conditions.find(condition => condition.type === 'date') && rule.conditions.find(condition => condition.type === 'date').from_date ?
                                    moment(rule.conditions.find(condition => condition.type === 'date').from_date, 'YYYY-MM-DD') : moment(this.props.date_from, 'YYYY-MM-DD'),
                                    rule.conditions && rule.conditions.find(condition => condition.type === 'date') && rule.conditions.find(condition => condition.type === 'date').to_date ?
                                    moment(rule.conditions.find(condition => condition.type === 'date').to_date, 'YYYY-MM-DD') : moment(this.props.date_to, 'YYYY-MM-DD')
                                  ]}
                                  value={[
                                    rule.conditions && rule.conditions.find(condition => condition.type === 'date') && rule.conditions.find(condition => condition.type === 'date').from_date ?
                                    moment(rule.conditions.find(condition => condition.type === 'date').from_date, 'YYYY-MM-DD') : moment(this.props.date_from, 'YYYY-MM-DD'),
                                    rule.conditions && rule.conditions.find(condition => condition.type === 'date') && rule.conditions.find(condition => condition.type === 'date').to_date ?
                                    moment(rule.conditions.find(condition => condition.type === 'date').to_date, 'YYYY-MM-DD') : moment(this.props.date_to, 'YYYY-MM-DD')
                                  ]}
                                  format={['DD/MM/YYYY', 'DD/MM/YY']}/>
                              </div>
                              <div style={{marginBottom: 15}}>
                                <Checkbox onChange={(e) => this.changeDay(e, index, relation)} value="1" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 1) ? true : false}>{t('M')}</Checkbox>
                                <Checkbox onChange={(e) => this.changeDay(e, index, relation)} value="2" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 2) ? true : false}>{t('T')}</Checkbox>
                                <Checkbox onChange={(e) => this.changeDay(e, index, relation)} value="3" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 3) ? true : false}>{t('W')}</Checkbox>
                                <Checkbox onChange={(e) => this.changeDay(e, index, relation)} value="4" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 4) ? true : false}>{t('TH')}</Checkbox>
                                <Checkbox onChange={(e) => this.changeDay(e, index, relation)} value="5" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 5) ? true : false}>{t('F')}</Checkbox>
                                <Checkbox onChange={(e) => this.changeDay(e, index, relation)} value="6" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 6) ? true : false}>{t('SA')}</Checkbox>
                                <Checkbox onChange={(e) => this.changeDay(e, index, relation)} value="0" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 0) !== undefined ? true : false}>{t('SU')}</Checkbox>
                              </div>
                              <div>
                                <TimePicker
                                  onChange={(time, timeString) => this.changeStartTime(time, timeString, index, relation)}
                                  defaultValue={
                                    rule.conditions && rule.conditions.find(condition => condition.type === 'hour') && rule.conditions.find(condition => condition.type === 'hour').from ?
                                    moment(rule.conditions.find(condition => condition.type === 'hour').from, 'HH:mm') : moment(this.props.displays[0].working_hours[0] ? this.props.displays[0].working_hours[0].start_time : '00:00', 'HH:mm')
                                  }
                                  value={
                                    rule.conditions && rule.conditions.find(condition => condition.type === 'hour') && rule.conditions.find(condition => condition.type === 'hour').from ?
                                    moment(rule.conditions.find(condition => condition.type === 'hour').from, 'HH:mm') : moment(this.props.displays[0].working_hours[0] ? this.props.displays[0].working_hours[0].start_time : '00:00', 'HH:mm')
                                  }
                                  format='HH:mm'
                                  style={{marginRight: 15}}
                                />
                                <TimePicker style={{ marginRight: "10px"}}
                                  onChange={(time, timeString) => this.changeEndTime(time, timeString, index, relation)}
                                  defaultValue={rule.conditions && rule.conditions.find(condition => condition.type === 'hour') && rule.conditions.find(condition => condition.type === 'hour').to ?
                                  moment(rule.conditions.find(condition => condition.type === 'hour').to, 'HH:mm') : moment(this.props.displays[0].working_hours[0] ? this.props.displays[0].working_hours[0].end_time : '23:59', 'HH:mm')}
                                  value={
                                    rule.conditions && rule.conditions.find(condition => condition.type === 'hour') && rule.conditions.find(condition => condition.type === 'hour').to ?
                                    moment(rule.conditions.find(condition => condition.type === 'hour').to, 'HH:mm') : moment(this.props.displays[0].working_hours[0] ? this.props.displays[0].working_hours[0].end_time : '23:59', 'HH:mm')
                                  }
                                  format='HH:mm'
                                />
                                <p className="msgHoursOperation">
                                    {t('Hours of Operation')}
                                  <Tooltip
                                    placement="right"
                                    title={<span>{this.props.displays[0].working_hours && this.props.displays[0].working_hours.length !== 0? workingHoursList:t('Lit all day every day')}</span>}>
                                    <Icon style={{ fontSize: "16px", marginLeft: "12px" }}
                                      type="schedule" />
                                  </Tooltip>
                                </p>
                                {(rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.length<1)?
                                  <p className="alertMsg">{t('You must select at least one day')}</p>
                                : null}
                              </div>
                            </Card>
                          ))}
                        </div>
                      ) : null}
                    </Col>
                    {this.props.programmatic === 'traditional' &&
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{paddingLeft: 0, paddingRight: 0, textAlign: "center"}}>
                        {this.getNextVolumeDiscount(t, this.props.displays[0], date_from, date_to)}
                      </Col>
                    }
                  </div>
                ))}
              </Slider>
              <Modal
                visible={this.state.canvaModalVisible}
                onCancel={this.handleCanvaClose}
                footer={null}
              >
                <div className="modalCanva">
                  <h2>{t('What resolution will your main Spot have?')}?</h2>
                </div>
                <div className="modalContent">
                  <Input
                    autoFocus
                    name="width"
                    className="modalInput"
                    placeholder="Ancho (px)"
                    defaultValue={this.state.width}
                    onChange={this.handleInputChange}
                    prefix={<Icon type="column-width" className="inputIcon" />}
                    type="number"
                    suffix="px"
                    style={{marginBottom: "10px"}}
                    autoComplete="off"
                  />
                  <Input
                    name="height"
                    className="modalInput"
                    placeholder="Alto (px)"
                    defaultValue={this.state.height}
                    onChange={this.handleInputChange}
                    prefix={<Icon type="column-height" className="inputIcon" />}
                    onPressEnter={() => this.createCanvaDesign(this.state.relation)}
                    type="number"
                    suffix="px"
                    autoComplete="off"
                  />
                  <span style={{marginTop: "20px"}}>
                    Recuerda que debes crear una cuenta e iniciar sesión la primera vez que diseñes
                  </span>
                  <Button
                    className="modalButton"
                    onClick={() => this.createCanvaDesign(this.state.relation)}
                  >
                    {t('Start')}
                  </Button>
                </div>
              </Modal>
            </div>
          ) : (
            <div>
              {this.props.displays.map(display => (
                <ScreenPreview key={display.id}
                               display={display}
                               GetImpressionsToShowPerDay ={this.GetImpressionsToShowPerDay}
                               campaignInvoiceIssuingCountry = {this.props.campaignInvoiceIssuingCountry}
                               onChangeContent = {this.onChangeContent}
                               getNextVolumeDiscount = {this.getNextVolumeDiscount}
                               cloneAllSpots = {this.cloneAllSpots}
                               removeRelationFromDisplayView = {this.removeRelationFromDisplayView}
                               arrContentChanged = {arrContentChanged}
                               allDisplaysAreOwn = {allDisplaysAreOwn}
                               permissionsTransferSpots = {permissionsTransferSpots}
                               creditAvailableOnTransferredImpressions={this.props.creditAvailableOnTransferredImpressions}
                               updateCreditAvailable = {this.props.updateCreditAvailable}
                               calculateMax = {this.calculateMax}
                               transferModalVisible={transferModalVisible}
                               showTransferModal={this.showTransferModal}
                               calculatePromisedShows = {this.calculatePromisedShows}
                               totalPromisedShowsForCampaignApproved= {this.totalPromisedShowsForCampaignApproved}
                               audienceHoursOfDay = {this.props.audienceHoursOfDay}
                               audienceDaysOfWeek = {this.props.audienceDaysOfWeek}
                               getAudienceHoursOfDayRequest={this.props.getAudienceHoursOfDayRequest}
                               getAudienceDaysOfWeekRequest={this.props.getAudienceDaysOfWeekRequest}
                />
              ))}
            </div>
          )}
          {this.props.displays.length > 1 ? (
            <div className="viewAllScreens">
              <Button
                className="toggleButton"
                style={{ marginTop: 10, marginBottom: 10 }}
                onClick={this.toggleHidden.bind(this)}
              >
                {this.state.isHidden ? (
                  <span>
                    <Icon style={{ fontSize: "16px" }} type="down" /> {t('Show all screens')}
                  </span>
                ) : (
                  <span>
                    <Icon style={{ fontSize: "16px" }} type="up" /> {t('Hide Screens')}
                  </span>
                )}
              </Button>
            </div>
          ) : null
          }
        </Card>
      </ScreenPreviewHolder>
    );
  }
}

export default withTranslation()(ScreenTypePreviewComponent);
